import { Box, Container, Heading } from "@chakra-ui/react";

interface TitleProps {
  title: string;
}

const Title = ({ title }: TitleProps) => {
  return (
    <>
      <Box background={"custom.brand_primary"} py={"22px"}>
        <Container maxW={"container.xl"} px={{ base: "16px", md: "32px" }}>
          <Heading fontSize={"2xl"} fontWeight={"normal"} color={"white"}>
            {title}
          </Heading>
        </Container>
      </Box>
    </>
  );
};

export default Title;
