import React, {ChangeEvent, useEffect, useState} from "react";
import Title from "@components/title";
import AnimatedPage from "@components/animated-page";
import {
    Container,
    GridItem,
    SimpleGrid,
    Select as MySelect,
    Button,
    Spinner,
    Alert,
    Link,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton, ModalBody, ModalFooter, useDisclosure, Image, HStack, Icon, Circle
} from "@chakra-ui/react";
import {SingleDatepicker} from "@components/date-picker";
import TimePicker from "@components/time-picker";
import ReservationCard from "@components/reservation-card-workplace";
import {useDispatch} from "react-redux";
import {AppDispatch, RootState} from "@src/store";
import {
    clearReservations,
    getFeatures,
    getOpeningTimes,
    getObjectTypes, getReservationPossibilities,
    getSitesWorkplace,
    getObjectImages,
    getSpecialModes, clearFilteredReservations, getReservationSettings, getFloormap
} from "@features/reservation/action";
import {useAppSelector} from "@store/hooks";
import moment from "moment/moment";
import {useTranslation} from "react-i18next";
import {formatDate} from "@helpers/helpers";
import useTitle from "@hooks/useTitle";
import {FiExternalLink, FiMap} from "react-icons/fi";

const CreateWorkplaceReservation = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {t} = useTranslation();
    const [date, setDate] = useState(new Date());

    const {
        sitesWorkplace,
        filteredReservations,
        reservations,
        isReservationsLoading,
        openingTimes,
        reservationSettings,
        objectImages,
        floorMap
    } = useAppSelector(
        (state: RootState) => state.reservation
    );

    const {reservationBlockSize, maximumReservationTime} = reservationSettings;

    const [siteID, setSiteID] = useState<null | string>();
    const [startTimeArray, setStartTimeArray] = useState<any>();
    const [stopTimeArray, setStopTimeArray] = useState<any>();
    const [selectedStartTime, setSelectedStartTime] = useState('');
    const [selectedEndTime, setSelectedEndTime] = useState('');
    const {isOpen, onOpen, onClose} = useDisclosure();

    useTitle(t(("documentTitle.reservationManagement")))

    const search = (async () => {
        if (siteID && date && selectedStartTime && selectedEndTime) {
            await dispatch(getFloormap({siteID: siteID}))
            await dispatch(getObjectImages({siteID: siteID}))
            await dispatch(getReservationPossibilities({
                siteID: siteID,
                resStart: formatDate(date) + ' ' + selectedStartTime,
                resStop: formatDate(date) + ' ' + selectedEndTime
            }));
        }
    })

    const filterOpenDays = openingTimes?.filter((f: any) => f.openTimes?.some((o: any) => o?.startTime || o?.stopTime));

    const handleSite = async (event: ChangeEvent<HTMLSelectElement>) => {
        setSiteID(event.target.value)
        await dispatch(getOpeningTimes({siteID: event.target.value}))
    }

    let timeArr: any = [];

    function getRoundedDate(minutes: any, d = new Date()) {
        let ms = 1000 * 60 * minutes; // convert minutes to ms
        return new Date(Math.floor(d.getTime() / ms) * ms);
    }

    const timesList = (date: any, start: any, end: any, minutes_step: number) => {
        const roundedCurrentDateTime = getRoundedDate(reservationBlockSize, new Date())
        const todayStart = moment(formatDate(roundedCurrentDateTime) + ' ' + roundedCurrentDateTime.getHours().toString().padStart(2, '0') + ':' + roundedCurrentDateTime.getMinutes().toString().padStart(2, '0'))

        let dateStart = moment(formatDate(date) + ' ' + start);
        let dateEnd = moment(formatDate(date) + ' ' + end);

        const remainder = (dateEnd.minute() % reservationBlockSize);

        if (todayStart.format("YYYY-MM-DD") === dateStart.format("YYYY-MM-DD") && todayStart.format("HH:mm") > dateStart.format("HH:mm")) {
            timeArr.push(moment(todayStart).format("HH:mm"));
            while (timeArr[timeArr.length - 1] < moment(dateEnd).subtract(remainder, "minutes").format("HH:mm")) {
                timeArr.push(todayStart.add(minutes_step, 'minutes').format("HH:mm"));
            }
        } else {
            timeArr.push(dateStart.format("HH:mm"));
            while (timeArr[timeArr.length - 1] < moment(dateEnd).subtract(remainder, "minutes").format("HH:mm")) {
                timeArr.push(dateStart.add(minutes_step, 'minutes').format("HH:mm"));
            }
        }
    };

    const filteredClosingDays = openingTimes.length && openingTimes.length && openingTimes?.filter((f: any) => f.openTimes?.some((o: any) => (!o?.startTime || !o?.stopTime) || (f?.date === moment().format("YYYY-MM-DD") && o?.startDate < moment().format("HH:mm"))));
    let closingDays = []

    for (let i = 0; i < filteredClosingDays.length; i++) {
        closingDays.push(new Date(moment(filteredClosingDays[i].date).format('YYYY-MM-DD')).getTime())
    }

    // @ts-ignore
    useEffect(() => {
        (async () => {
            await dispatch(getSitesWorkplace())
            await dispatch(getFeatures())
            await dispatch(getSpecialModes())
            await dispatch(getObjectTypes())
        })();

        return async () => {
            await dispatch(clearFilteredReservations())
            await dispatch(clearReservations())
        }
    }, [dispatch]);

    useEffect(() => {
        if (reservationBlockSize && siteID && openingTimes?.length) {
            const t = openingTimes?.filter((item: any) => item.date === formatDate(date))[0];
            if (t?.openTimes.every((v: any) => {
                return v !== null || !(t?.date === moment().format('YYYY-MM-DD') && v?.stopTime < moment().format("HH:mm"));
            })) {
                t?.openTimes.filter((val: any) => val !== null).map(({startTime, stopTime}: any) => {
                    return timesList(date, startTime.split(':')[0].padStart(2, '0') + ':' + startTime.split(':')[1].padStart(2, '0'), stopTime.split(':')[0].padStart(2, '0') + ':' + stopTime.split(':')[1].padStart(2, '0'), reservationBlockSize)
                })
                const stopTimeArr = t?.openTimes.filter((val: any) => val !== null).map(({stopTime}: any) => {
                    return stopTime.split(':')[0].padStart(2, '0') + ':' + stopTime.split(':')[1].padStart(2, '0')
                })
                const newFilteredTimeArray = timeArr.filter((el: any) => {
                    return stopTimeArr.indexOf(el) < 0 && el !== "23:45";
                });
                setStartTimeArray(newFilteredTimeArray)
                setStopTimeArray(timeArr)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteID, openingTimes, reservationBlockSize, date])

    useEffect(() => {
        (async () => {
            if (sitesWorkplace.length && !reservations.length && !siteID) {
                setSiteID(sitesWorkplace[0]?.ID)
            }

            if (siteID) {
                setSelectedStartTime('');
                setSelectedEndTime('');
                await dispatch(getOpeningTimes({siteID: siteID}))
                await dispatch(getReservationSettings({siteID: siteID}))
            }


        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sitesWorkplace, siteID, dispatch])

    return (
        <>
            <Title title={t("createWorkPlace.title")}/>
            <AnimatedPage animationType={"FadeIn"}>
                <Container
                    maxW={"container.xl"}
                    p={{base: "22px 16px", md: "45px 32px"}}
                >
                    <SimpleGrid
                        bg={"custom.gray_100"}
                        padding={"32px"}
                        columns={{base: 1}}
                        alignItems={"flex-start"}
                        gap={"16px"}
                        mb={"45px"}
                    >
                        <SimpleGrid columns={{base: 1, md: 4}} gap={"20px"}>
                            <GridItem>
                                <SingleDatepicker
                                    name="date-input"
                                    date={date}
                                    onDateChange={async (date: Date) => {
                                        setDate(date);
                                        setSelectedStartTime('')
                                        setSelectedEndTime('')
                                    }}
                                    disabled={!filterOpenDays.length}
                                    maxDate={openingTimes[openingTimes?.length - 1]?.date}
                                    closingDays={closingDays}
                                />
                            </GridItem>
                            <GridItem>
                                <MySelect
                                    variant={"black"}
                                    width={"auto"}
                                    onChange={handleSite}
                                >
                                    {sitesWorkplace.map(({label, ID}: any, index: number) => (
                                        <option key={index} value={ID}>
                                            {label}
                                        </option>
                                    ))}
                                </MySelect>
                            </GridItem>
                            <GridItem>
                                {
                                    <TimePicker
                                        startTimeArray={startTimeArray}
                                        stopTimeArray={stopTimeArray}
                                        inputPlaceholder={t('newReservation.search.selectTime.placeholder')}
                                        setSelectedStartTime={setSelectedStartTime}
                                        selectedStartTime={selectedStartTime}
                                        setSelectedEndTime={setSelectedEndTime}
                                        selectedEndTime={selectedEndTime}
                                        disabled={!siteID}
                                        reservationBlockSize={reservationBlockSize}
                                        maximumReservationTime={maximumReservationTime}
                                    />
                                }
                            </GridItem>
                            <GridItem>
                                <Button variant={'primary'} width={'100%'} onClick={search}
                                        isDisabled={!siteID || !selectedStartTime || !selectedEndTime}>{t('newReservation.search.btn')}</Button>
                            </GridItem>
                        </SimpleGrid>
                    </SimpleGrid>

                    {isReservationsLoading ? <Spinner/> :
                        <>
                            {filteredReservations.length ? <>
                                {(floorMap.url || process.env.REACT_APP_BIB_URL) &&
                                    <Alert status='info' p={"22px 16px"} mb={"40px"}>
                                        <HStack spacing={"32px"}>
                                            {floorMap.url &&
                                                <HStack spacing={"12px"}>
                                                    <Circle size='32px' bg='custom.brand_primary' color='white'>
                                                        <Icon as={FiMap} />
                                                    </Circle>
                                                    <Link textDecoration={"underline"} onClick={onOpen}>{t('newReservation.map.title')}</Link>
                                                </HStack>
                                            }
                                            {process.env.REACT_APP_BIB_URL &&
                                                <HStack spacing={"12px"}>
                                                    <Circle size='32px' bg='custom.brand_primary' color='white'>
                                                        <Icon as={FiExternalLink}/>
                                                    </Circle>
                                                    <Link textDecoration={"underline"}
                                                          href={process.env.REACT_APP_BIB_URL} isExternal>
                                                        {t('newReservation.website.title')}
                                                    </Link>
                                                </HStack>
                                            }
                                        </HStack>
                                    </Alert>
                                }
                                {isOpen && (
                                    <Modal isOpen={isOpen} onClose={onClose} isCentered>
                                        <ModalOverlay/>
                                        <ModalContent>
                                            <ModalHeader>
                                                {t("newReservation.map.title")}
                                            </ModalHeader>
                                            <ModalCloseButton/>


                                            <ModalBody>
                                                <Image src={floorMap.url}/>
                                            </ModalBody>


                                            <ModalFooter>
                                                <Button onClick={onClose}>
                                                    {t("newReservation.map.close")}
                                                </Button>
                                            </ModalFooter>
                                        </ModalContent>
                                    </Modal>
                                )}
                            </> : null
                            }
                            <SimpleGrid columns={{base: 1, lg: 2}} gap={"40px"}>
                                {filteredReservations.map((data: any, index: any) => (
                                    <GridItem key={index}>
                                        <ReservationCard data={data}
                                                         selectedDate={date}
                                                         selectedStartTime={selectedStartTime}
                                                         selectedEndTime={selectedEndTime}
                                                         objectImages={objectImages}
                                        />
                                    </GridItem>
                                ))}
                            </SimpleGrid>
                        </>
                    }
                </Container>
            </AnimatedPage>
        </>
    );
};

export default CreateWorkplaceReservation;