import React, { ReactNode } from "react";
import { chakra, BoxProps } from "@chakra-ui/react";
import { isValidMotionProp, motion } from "framer-motion";

interface Props extends BoxProps {
  animationType: "FadeIn" | "FadeInUp";
  children: ReactNode;
}

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and the children prop to be forwarded.
   * All other chakra props not matching the motion props will still be forwarded.
   */
  shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === "children",
});

const Index = ({ animationType, children, ...prop }: Props) => {
  const FadeInUp = {
    hidden: {
      y: 20,
      opacity: 0,
    },
    enter: {
      y: 0,
      opacity: 1,
    },
    exit: {
      y: 20,
      opacity: 0,
    },
  };

  const FadeIn = {
    hidden: { opacity: 0 },
    enter: {
      opacity: 1,
    },
    exit: { opacity: 0 },
  };

  return (
    <ChakraBox
      variants={
        animationType === "FadeIn"
          ? FadeIn
          : animationType === "FadeInUp"
          ? FadeInUp
          : {}
      }
      initial={"hidden"}
      animate={"enter"}
      exit={"exit"}
      // @ts-ignore - type errors chakra - pr?
      transition={{
        duration: 0.5,
        type: "easeInOut",
      }}
      {...prop}
    >
      {children}
    </ChakraBox>
  );
};

export default Index;
