import { configureStore, combineReducers } from "@reduxjs/toolkit";

// Reducer imports
import authSlice from "@features/auth/slice";
import userSlice from "@features/user/slice";
import printSlice from "@features/print/slice";
import reservationSlice from "@features/reservation/slice";
import uploadSlice from "@features/upload/slice";
import websiteColorsSlice from "@features/colors/slice";

const rootReducer = combineReducers({
  auth: authSlice,
  user: userSlice,
  print: printSlice,
  reservation: reservationSlice,
  upload: uploadSlice,
  websiteColors: websiteColorsSlice
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
