const progress = {
  // Styles for the base style
  baseStyle: {
    borderRadius: "50%",
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    brand: {
      track: {
        borderRadius: "8px",
      },
      filledTrack: {
        bg: "custom.brand_primary",
      },
    },
    error: {
      track: {
        borderRadius: "8px",
        bg: "error.100",
      },
      filledTrack: {
        bg: "error.400",
      },
    },
  },
  // The default `size` or `variant` values
  defaultProps: {
    size: "sm",
  },
};

export default progress;
