import { createSlice } from "@reduxjs/toolkit";
import { handleLogin, handleLogout } from "@features/auth/action";
import { LoginStateI } from "@features/auth/types";

const userToken = localStorage.getItem("userToken")
  ? localStorage.getItem("userToken")
  : null;

const userSettings = localStorage.getItem("userSettings" )
    ? JSON.parse(localStorage.getItem("userSettings") || '{}')
    : null;

const initialState: LoginStateI = {
  loading: false,
  userInfo: null,
  userToken: userToken,
  userSettings: userSettings,
  error: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    saveUser: (state, action) => action.payload,
  },
  extraReducers: (builder) => {
    builder.addCase(handleLogin.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(handleLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.userToken = action.payload?.sessionToken;
      state.userSettings = action.payload;
    });
    builder.addCase(handleLogin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(handleLogout.fulfilled, (state) => {
      state.userToken = null;
    });
  },
});

export default authSlice.reducer;
