import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

const PageSpinner = () => {
  return (
    <>
      <Flex
        pos={"fixed"}
        top={0}
        right={0}
        bottom={0}
        left={0}
        zIndex={9999}
        background={"white"}
      >
        <Spinner
          pos={"absolute"}
          top={0}
          right={0}
          bottom={0}
          left={0}
          margin={"auto"}
          thickness={"3px"}
          emptyColor={"custom.gray_200"}
          color={"custom.brand_primary"}
          size={"xl"}
        />
      </Flex>
    </>
  );
};

export default PageSpinner;
