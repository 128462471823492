import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "@utils/http";
import { toast } from "react-toastify";
import { uploadActionI } from "@features/upload/type";
import i18n from "i18next";

export const handleUploadFile = createAsyncThunk(
  "byod/files/{filename}",

  async ({ fileName, body }: uploadActionI, { rejectWithValue }) => {
    try {
      const res = await http.post(`/byod/files/${fileName}`, body);

      toast.success(i18n.t("printManagement.upload.toast.successMsg"));

      return res.data;
    } catch (error: any) {
      if (error.status === 413) {
        toast.error(i18n.t("printManagement.upload.toast.fileSizeTooBig"));
      } else if (error.status === 415) {
        toast.error(i18n.t("printManagement.upload.toast.fileNotSupported"));
      } else {
        toast.error(i18n.t("printManagement.upload.toast.errorMsg"));
      }
      return rejectWithValue(error);
    }
  }
);

export const revertAll = createAction("REVERT_ALL");
