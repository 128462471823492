import { createSlice } from "@reduxjs/toolkit";
import {
  deletePrintJobs,
  getPrintJobs,
  getPrintJobsByIds,
  updatePrintJob,
} from "@features/print/action";
import { printJobsState } from "@features/print/types";

const initialState: printJobsState = {
  isLoading: false,
  printJobs: [],
  printJobsByIds: [],
  printJob: null,
  error: null,
};

export const printSlice = createSlice({
  name: "print",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPrintJobs.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getPrintJobs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.printJobs = action.payload;
      state.printJob = null;
    });
    builder.addCase(getPrintJobs.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getPrintJobsByIds.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getPrintJobsByIds.fulfilled, (state, action) => {
      state.isLoading = false;
      state.printJobsByIds = action.payload;
    });
    builder.addCase(getPrintJobsByIds.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(updatePrintJob.pending, (state) => {
      state.error = null;
    });
    builder.addCase(updatePrintJob.rejected, (state, action) => {
      state.error = action.payload;
    });
    builder.addCase(deletePrintJobs.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(deletePrintJobs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deletePrintJobs.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        printJobs: state.printJobs.filter((printJob) => {
          if (Array.isArray(action.meta.arg.ids)) {
            return !action.meta.arg.ids.includes(printJob.ID);
          } else {
            return printJob.ID !== action.meta.arg.ids;
          }
        }),
      };
    });
  },
});

export default printSlice.reducer;
