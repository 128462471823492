const input = {
  // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {
    md: {
      field: {
        height: "44px",
      },
    },
  },
  // Styles for the visual style variations
  variants: {
    black: {
      field: {
        backgroundColor: "black",
        borderRadius: 0,
        color: "white",

        "> option, > optgroup": {
          color: "black",
        },
      },
      icon: {
        color: "white",
      },
    },
    filled: {
      field: {
        backgroundColor: "white",
        borderRadius: 0,
        color: "custom.gray_800",
        borderWidth: "2px",
        borderColor: "custom.gray_300",
        height: "44px",

        "> option, > optgroup": {
          color: "custom.gray_800",
        },
        _hover: {
          background: "white",
        },
        _focus: {
          background: "white",
        },
      },
      icon: {
        color: "custom.gray_800",
      },
    },
  },
  // The default `size` or `variant` values
  defaultProps: {},
};

export default input;
