import * as React from "react";
import {
  Box,
  Circle,
  Container,
  Heading,
  Icon,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useEffect } from "react";
import PrintPreferences from "@components/print-preferences";
import AnimatedPage from "@components/animated-page";
import Title from "@components/title";
import { useAppSelector } from "@store/hooks";
import { AppDispatch, RootState } from "@store/index";
import Spinner from "@components/spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { getPrintJobsByIds, updatePrintJob } from "@features/print/action";
import { useDispatch } from "react-redux";
import { printJobsRes } from "@features/print/types";
import { FiFile } from "react-icons/fi";
import { formValues } from "../../types/form";
import { useTranslation } from "react-i18next";
import useTitle from "@hooks/useTitle";

const BeheerPrint = () => {
  const { isLoading } = useAppSelector((state: RootState) => state.print);
  const { printJobsByIds } = useAppSelector((state: RootState) => state.print);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const locationState = useLocation().state as {
    selected: printJobsRes[];
    managePrintsRedirectUrl: string;
  };

  useTitle(t(("documentTitle.printManagement")))

  useEffect(() => {
    const Ids = Object.values(locationState.selected.map(({ ID }) => ID)).join(
      ","
    );
    (async () => {
      return unwrapResult(await dispatch(getPrintJobsByIds({ id: Ids })));
    })();
  }, [dispatch, locationState]);

  const handleSubmit = (values: formValues) => {
    const updateSelectedPrints: printJobsRes[] = [];

    locationState.selected.forEach(({ ID }) => {
      updateSelectedPrints.push({ ID: ID, ...values, FREE_REPRINT: 0 });
    });

    return new Promise((resolve, reject) => {
      dispatch(
        updatePrintJob({
          body: updateSelectedPrints,
        })
      )
        .then(() => resolve(navigate(locationState.managePrintsRedirectUrl)))
        .catch((err) => reject(err));
    });
  };

  if (isLoading) return <Spinner />;
  return (
    <>
      <Box>
        <Title title={t("printManagement.managePrints.title")} />
        <AnimatedPage animationType={"FadeInUp"}>
          <Container
            maxW={"container.xl"}
            p={{ base: "22px 16px", md: "45px 32px" }}
          >
            <SimpleGrid
              columns={{ base: 1, lg: 2 }}
              spacing={{ base: "20px", md: "40px", lg: "80px" }}
            >
              <Box>
                <Heading
                  mb={{ base: "20px", md: "32px" }}
                  fontSize={"2xl"}
                  fontWeight={"normal"}
                >
                  {t("printManagement.managePrints.subtitle")}
                </Heading>
                <SimpleGrid
                  columns={{ base: 1, sm: 2, md: 3, lg: 2, xl: 3 }}
                  gap={22}
                >
                  {locationState.selected &&
                    locationState.selected.map((item, index) => {
                      return (
                        <Box
                          borderWidth={"1px"}
                          borderColor={"custom.gray_300"}
                          p={"16px"}
                          key={index}
                        >
                          <VStack spacing={"22px"} alignItems={"flex-start"}>
                            <VStack
                              spacing={"12px"}
                              alignItems={"flex-start"}
                              w={"full"}
                            >
                              <Circle size={"40px"} bg={"custom.brand_primary"}>
                                <Icon
                                  as={FiFile}
                                  boxSize={"20px"}
                                  color={"custom.brand_primaryLight"}
                                />
                              </Circle>
                              <Text wordBreak={"break-all"}>
                                {item.DOCUMENT_NAME}
                              </Text>
                            </VStack>
                          </VStack>
                        </Box>
                      );
                    })}
                </SimpleGrid>
              </Box>
              <Box>
                <PrintPreferences
                  onSubmit={handleSubmit}
                  currentSettings={printJobsByIds}
                  enableSubmitButtonOnChange={true}
                />
              </Box>
            </SimpleGrid>
          </Container>
        </AnimatedPage>
      </Box>
    </>
  );
};

export default BeheerPrint;
