import {createAction, createSlice} from "@reduxjs/toolkit";
import {
    getReservations,
    getSites,
    getSitesWorkplace,
    getSpecialModes,
    getObjectTypes,
    getOpeningTimes,
    getFeatures,
    getObjectsFiltered,
    getMyReservations,
    deleteMyReservation,
    getReservationPossibilities,
    makeReservation,
    clearReservations,
    clearFilteredReservations,
    getReservationSettings,
    reservationSignin, getObjectImages, getFloormap
} from "@features/reservation/action";

const initialState: any = {
    isLoading: false,
    objectImages: [],
    isReservationsLoading: false,
    isReservationsFilteredLoading: false,
    sites: [],
    sitesWorkplace: [],
    specialModes: [],
    features: [],
    res: [],
    reservations: [],
    reservationSettings: {},
    filteredReservations: [],
    filteredItems: [],
    activeFilters: false,
    myReservations: [],
    objectTypes: [],
    openingTimes: [],
    error: null,
    reservationCreated: false,
    floorMap: {}
};

const clearAction = createAction('clear');

export const reservationSlice = createSlice({
    name: "reservation",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(clearReservations.fulfilled, (state, action) => {
            state.reservations = []
        });

        builder.addCase(clearFilteredReservations.fulfilled, (state, action) => {
            state.filteredReservations = []
            state.activeFilters = false
        });

        builder.addCase(clearAction, () => initialState);

        builder.addCase(getObjectImages.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(getObjectImages.fulfilled, (state, action) => {
            state.isLoading = false;
            state.objectImages = action.payload;
            state.error = null;
        });
        builder.addCase(getObjectImages.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(getFloormap.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(getFloormap.fulfilled, (state, action) => {
            state.isLoading = false;
            state.floorMap = action.payload;
            state.error = null;
        });
        builder.addCase(getFloormap.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(getSites.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(getSites.fulfilled, (state, action) => {
            state.isLoading = false;
            state.sites = action.payload;
            state.error = null;
        });
        builder.addCase(getSites.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(getSitesWorkplace.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(getSitesWorkplace.fulfilled, (state, action) => {
            state.isLoading = false;
            state.sitesWorkplace = action.payload;
            state.error = null;
        });
        builder.addCase(getSitesWorkplace.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(getSpecialModes.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(getSpecialModes.fulfilled, (state, action) => {
            state.isLoading = false;
            state.specialModes = action.payload;
            state.error = null;
        });
        builder.addCase(getSpecialModes.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(getFeatures.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(getFeatures.fulfilled, (state, action) => {
            state.isLoading = false;
            state.features = action.payload;
            state.error = null;
        });
        builder.addCase(getFeatures.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(getObjectsFiltered.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(getObjectsFiltered.fulfilled, (state, action) => {
            return {
                ...state,
                isLoading: false,
                error: null,
                activeFilters : true,
                filteredReservations: state.reservations.filter(({ ID: id1 }: any) => action.payload.some(({ ID: id2 }: any) => id2 === id1))
            };
        });
        builder.addCase(getObjectsFiltered.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(getObjectTypes.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(getObjectTypes.fulfilled, (state, action) => {
            state.isLoading = false;
            state.objectTypes = action.payload;
            state.error = null;
        });
        builder.addCase(getObjectTypes.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(getReservationPossibilities.pending, (state) => {
            state.isReservationsLoading = true;
            state.reservations = []
            state.error = null;
        });
        builder.addCase(getReservationPossibilities.fulfilled, (state, action) => {
            state.isReservationsLoading = false;
            state.reservations = action.payload;
            state.filteredReservations = action.payload;
            state.error = null;
        });
        builder.addCase(getReservationPossibilities.rejected, (state, action) => {
            state.isReservationsLoading = false;
            state.error = action.payload;
        });

        builder.addCase(makeReservation.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });

        builder.addCase(makeReservation.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = null;
        });

        builder.addCase(makeReservation.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(getReservations.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(getReservations.fulfilled, (state, action) => {
            state.isLoading = false;
            state.reservations = action.payload;
            state.error = null;
        });
        builder.addCase(getReservations.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(getOpeningTimes.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(getOpeningTimes.fulfilled, (state, action) => {
            state.isLoading = false;
            state.openingTimes = action.payload;
            state.error = null;
        });
        builder.addCase(getOpeningTimes.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(getMyReservations.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(getMyReservations.fulfilled, (state, action) => {
            state.isLoading = false;
            state.myReservations = action.payload;
            state.error = null;
        });
        builder.addCase(getMyReservations.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(getReservationSettings.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(getReservationSettings.fulfilled, (state, action) => {
            state.isLoading = false;
            state.reservationSettings = action.payload;
            state.error = null;
        });
        builder.addCase(getReservationSettings.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(deleteMyReservation.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
        builder.addCase(deleteMyReservation.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(deleteMyReservation.fulfilled, (state, action) => {
            return {
                ...state,
                isLoading: false,
                myReservations: state.myReservations.filter((myReservation: any) => {
                    return myReservation.ID !== action.meta.arg.reservationId;
                }),
                myReservationDeleted: true
            };
        });

        builder.addCase(reservationSignin.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });

        builder.addCase(reservationSignin.fulfilled, (state, action) => {
            state.isLoading = false;
            state.error = null;
        });

        builder.addCase(reservationSignin.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    },
});

export default reservationSlice.reducer;
