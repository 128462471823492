import React, { Fragment, useRef, useState, ReactNode } from "react";
import { isEmpty, isNil, map } from "lodash";
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  InputLeftElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Text,
  useOutsideClick,
  VStack,
  InputGroup,
  Input,
} from "@chakra-ui/react";
import { FiCalendar } from "react-icons/fi";
import {
  DateObj,
  useDayzed,
  RenderProps,
  GetBackForwardPropsOptions, Calendar
} from "dayzed";
import moment from "moment";

const MONTH_NAMES_DEFAULT = [
  "Jan",
  "Feb",
  "Maa",
  "Apr",
  "Mei",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Dec",
];

const DAY_NAMES_DEFAULT = ["Ma", "Di", "Wo", "Do", "Vr", "Za", "Zo"];
const DATE_FORMAT_DEFAULT = "DD-MM-YYYY";

interface SingleDatepickerBackButtonsProps {
  calendars: Calendar[];
  getBackProps: (data: GetBackForwardPropsOptions) => Record<string, any>;
}

interface SingleDatepickerForwardButtonsProps {
  calendars: Calendar[];
  getForwardProps: (data: GetBackForwardPropsOptions) => Record<string, any>;
}

export interface SingleDatepickerProps {
  disabled?: boolean;
  onDateChange: any;
  id?: string;
  name?: string;
  date: Date | undefined;
  configs?: SingleDatepickerConfigs;
  maxDate?: any;
  closingDays?: any;
}

export interface SingleDatepickerConfigs {
  dateFormat: string;
  monthNames: string[];
  dayNames: string[];
}

const SingleDatepickerBackButtons = (
  props: SingleDatepickerBackButtonsProps
) => {
  const { calendars, getBackProps } = props;
  return (
    <Fragment>
      <Button
        {...getBackProps({
          calendars,
          offset: 12,
        })}
        variant="ghost"
        size="sm"
      >
        {"<<"}
      </Button>
      <Button {...getBackProps({ calendars })} variant="ghost" size="sm">
        {"<"}
      </Button>
    </Fragment>
  );
};

const SingleDatepickerForwardButtons = (
  props: SingleDatepickerForwardButtonsProps
) => {
  const { calendars, getForwardProps } = props;
  return (
    <Fragment>
      <Button {...getForwardProps({ calendars })} variant="ghost" size="sm">
        {">"}
      </Button>
      <Button
        {...getForwardProps({
          calendars,
          offset: 12,
        })}
        variant="ghost"
        size="sm"
      >
        {">>"}
      </Button>
    </Fragment>
  );
};

const SingleDatepickerCalendar = (
  props: RenderProps & { configs: SingleDatepickerConfigs, disabledDates: any, maxDate: any; }
) => {
  const { calendars, getDateProps, getBackProps, getForwardProps, configs, disabledDates, maxDate } =
    props;

  if (isEmpty(calendars)) {
    return null;
  }

  return (
    <HStack className="datepicker-calendar">
      {map(calendars, (calendar) => {
        return (
          <VStack key={`${calendar.month}${calendar.year}`}>
            <HStack>
              <SingleDatepickerBackButtons
                calendars={calendars}
                getBackProps={getBackProps}
              />
              <Heading size="sm" textAlign="center">
                {configs.monthNames[calendar.month]} {calendar.year}
              </Heading>
              <SingleDatepickerForwardButtons
                calendars={calendars}
                getForwardProps={getForwardProps}
              />
            </HStack>
            <Divider />
            <SimpleGrid columns={7} spacing={2} textAlign="center">
              {map(configs.dayNames, (day) => (
                <Box key={`${calendar.month}${calendar.year}${day}`}>
                  <Text fontSize="sm" fontWeight="semibold">
                    {day}
                  </Text>
                </Box>
              ))}
              {map(calendar.weeks, (week, weekIndex) => {
                return map(week, (dateObj: DateObj, index) => {
                  const { date, today, selected } = dateObj;
                  const key = `${calendar.month}${calendar.year}${weekIndex}${index}`;

                  return (
                    <Button
                      {...getDateProps({
                        dateObj,
                      })}
                      key={key}
                      size="sm"
                      isDisabled={new Date(Date.now() - 86400000) > date || disabledDates.includes(new Date(moment(date).format('YYYY-MM-DD')).getTime()) || new Date(moment(maxDate).format('YYYY-MM-DD')) < date}
                      variant={"datePicker"}
                      borderColor={today ? "custom.brand_primary" : "transparent"}
                      bg={selected ? "custom.brand_primary" : undefined}
                      color={selected ? "white" : undefined}
                      _hover={{background: "custom.brand_primary", color: "white"}}
                    >
                      {date.getDate()}
                    </Button>
                  );
                });
              })}
            </SimpleGrid>
          </VStack>
        );
      })}
    </HStack>
  );
};

export const SingleDatepicker = ({
  configs = {
    dateFormat: DATE_FORMAT_DEFAULT,
    monthNames: MONTH_NAMES_DEFAULT,
    dayNames: DAY_NAMES_DEFAULT,
  },
  ...props
}: SingleDatepickerProps) => {
  const { date, name, disabled, onDateChange, id, maxDate, closingDays } = props;

  const ref = useRef<HTMLElement>(null);
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const icon: ReactNode = <FiCalendar size={"20px"} />;

  useOutsideClick({
    ref: ref,
    handler: () => setPopoverOpen(false),
  });

  const onDateSelected = (options: { selectable: boolean; date: Date }) => {
    const { selectable, date } = options;
    if (!selectable) return;
    if (!isNil(date)) {
      onDateChange(date);
      setPopoverOpen(false);
      return;
    }
  };

  const dayzedData = useDayzed({
    showOutsideDays: true,
    minDate: new Date(Date.now() - 86400000),
    maxDate: new Date(moment(maxDate).format('YYYY-MM-DD')),
    onDateSelected,
    selected: date,
    firstDayOfWeek: 1,
  });

  return (
    <Popover
      placement="bottom-start"
      variant="responsive"
      isOpen={popoverOpen}
      onClose={() => setPopoverOpen(false)}
      initialFocusRef={initialFocusRef}
      isLazy
    >
      <PopoverTrigger>
        <InputGroup onClick={() => setPopoverOpen(!popoverOpen)}>
          <Input
            id={id}
            autoComplete="off"
            isDisabled={disabled}
            ref={initialFocusRef}
            name={name}
            value={moment(date).format(configs.dateFormat)}
            onChange={(e) => e.target.value}
            variant={"filled"}
          />
          <InputLeftElement color="custom.gray_700" children={icon} />
        </InputGroup>
      </PopoverTrigger>
      <PopoverContent ref={ref} width={"inherit"}>
        <PopoverBody
          padding={"10px 5px"}
          borderWidth={1}
          borderColor="custom.gray_300"
        >
          <SingleDatepickerCalendar {...dayzedData} configs={configs} disabledDates={closingDays} maxDate={maxDate} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
