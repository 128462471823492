const popover = {
  // Styles for the base style
  baseStyle: {
    content: {
      borderRadius: "0",
      _focus: {
        boxShadow: "none",
      },
    },
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {},
};

export default popover;
