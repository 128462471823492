const avatar = {
  // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {
    md: {
      container: {
        width: "40px",
        height: "40px",
      },
      label: {
        fontSize: "14px",
      },
    },
  },
  // Styles for the visual style variations
  variants: {
    brand: {
      container: {
        bg: "custom.brand_primary",
        color: "white",
      },
    },
  },
  // The default `size` or `variant` values
  defaultProps: {},
};

export default avatar;
