const tabs = {
  // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {
    primary: {
      tab: {
        padding: "12px",
        _selected: {
          background: "custom.gray_100",
        },
      },
      tabpanel: {
        padding: 0,
      },
    },
  },
  // The default `size` or `variant` values
  defaultProps: {},
};

export default tabs;
