import * as React from "react";
import { Box, Container, SimpleGrid } from "@chakra-ui/react";
import AnimatedPage from "@components/animated-page";
import Title from "@components/title";
import { useAppSelector } from "@store/hooks";
import { RootState } from "@src/store";
import Spinner from "@components/spinner";
import UploadBox from "@components/upload-box";
import { useTranslation } from "react-i18next";
import useTitle from "@hooks/useTitle";

export const NieuwePrints = () => {
  const { isLoading } = useAppSelector((state: RootState) => state.user);
  const { t } = useTranslation();

  useTitle(t(("documentTitle.printManagement")))

  if (isLoading) return <Spinner />;
  return (
    <>
      <Box>
        <Title title={t("printManagement.newPrints.title")} />
        <AnimatedPage animationType={"FadeInUp"}>
          <Container
            maxW={"container.xl"}
            p={{ base: "22px 16px", md: "45px 32px" }}
          >
            <SimpleGrid
              columns={{ base: 1, md: 1 }}
              spacing={{ base: "20px", lg: "40px", xl: "80px" }}
              mb={"50px"}
            >
              <UploadBox />
            </SimpleGrid>
          </Container>
        </AnimatedPage>
      </Box>
    </>
  );
};
