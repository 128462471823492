import {useDispatch} from "react-redux";
import {
    Avatar,
    Container,
    Flex,
    HStack,
    IconButton,
    Image,
    ListItem,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    UnorderedList,
    VStack,
    useDisclosure,
    MenuGroup,
    MenuDivider,
    Box,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Button,
    List,
    SlideFade,
} from "@chakra-ui/react";
import NumberFormat from "react-number-format";
import {FiChevronDown, FiChevronUp, FiX, FiMenu} from "react-icons/fi";
import {BiWalletAlt} from "react-icons/bi";
import {useTranslation} from "react-i18next";
import {AppDispatch, RootState} from "@src/store";
import {useAppSelector} from "@store/hooks";
import {handleLogout} from "@features/auth/action";
import LocalizedLink from "@components//localized-link";
import LanguageDropdown from "@components//language-dropdown";
import IsolatedMobileNavItem from "@components/navbar/isolated-mobile-nav-item";
import {NavItemsI} from "@components/navbar/interfaces";
import logo from "@assets/images/logo.svg";

const Navbar = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {isOpen, onToggle} = useDisclosure();
    const {t} = useTranslation();
    const {userInfo} = useAppSelector((state: RootState) => state.user);
    const {userSettings} = useAppSelector((state: RootState) => state.auth);

    const {BYODAvailable, reservePCAvailable, reservePlaceAvailable} = userSettings;

    const NAV_ITEMS: NavItemsI[] = [
        {
            title: `${t("navbar.printManagement.title")}`,
            active: BYODAvailable,
            children: [
                {
                    id: 1,
                    subTitle: `${t("navbar.printManagement.newPrints.title")}`,
                    path: `${t("pageRoutes.nieuwe-prints")}`,
                    active: BYODAvailable,
                },
                {
                    id: 2,
                    subTitle: `${t("navbar.printManagement.myQueue.title")}`,
                    path: `${t("pageRoutes.mijn-wachtrij")}`,
                    active: BYODAvailable,
                },
                {
                    id: 3,
                    subTitle: `${t("navbar.printManagement.recentPrints.title")}`,
                    path: `${t("pageRoutes.recente-prints")}`,
                    active: BYODAvailable,
                },
            ],
        },
        {
            title: `${t("navbar.reservationManagement.title")}`,
            active: reservePCAvailable || reservePlaceAvailable,
            children: [
                {
                    id: 4,
                    subTitle: `${t("navbar.reservationManagement.createPCReservation.title")}`,
                    path: `${t("pageRoutes.create-pc-reservation")}`,
                    active: reservePCAvailable
                },
                {
                    id: 5,
                    subTitle: `${t("navbar.reservationManagement.createWorkspaceReservation.title")}`,
                    path: `${t("pageRoutes.create-workplace-reservation")}`,
                    active: reservePlaceAvailable
                },
                {
                    id: 6,
                    subTitle: "Mijn reservaties",
                    active: reservePCAvailable,
                    path: `${t("pageRoutes.mijn-reservaties")}`,
                },
            ],
        }
    ];

    return (
        <>
            <Container maxW={"container.xl"} p={{base: "16px", md: "16px 32px"}}>
                <Flex justifyContent={"space-between"}>
                    <HStack spacing={{md: "24px", lg: "32px"}}>
                        <Box>
                            <LocalizedLink w={"full"}>
                                <Image src={logo} alt={"logo"} maxW={"160px"} w={"full"} />
                            </LocalizedLink>
                        </Box>
                        <UnorderedList
                            display={{base: "none", md: "flex"}}
                            listStyleType={"none"}
                        >
                            <HStack spacing={{lg: "12px"}}>
                                <>
                                    {NAV_ITEMS.filter(({active}) => active).map(({title, children}, index) => (
                                        <Popover
                                            placement={"bottom-start"}
                                            variant="responsive"
                                            key={index}
                                        >
                                            {({isOpen}) => (
                                                <>
                                                    <PopoverTrigger>
                                                        <Button
                                                            variant={"link"}
                                                            padding={"12px"}
                                                            transition={"all .5s ease-in-out"}
                                                            _hover={{
                                                                textDecoration: "none",
                                                                background: "custom.gray_100",
                                                            }}
                                                        >
                                                            <HStack spacing={"6px"}>
                                                                <Text>{title}</Text>
                                                                {isOpen ? <FiChevronUp/> : <FiChevronDown/>}
                                                            </HStack>
                                                        </Button>
                                                    </PopoverTrigger>
                                                    <PopoverContent>
                                                        <List>
                                                            {children.filter(({active}) => active).map(({id, subTitle, path}) => (
                                                                <ListItem key={id}>
                                                                    <LocalizedLink
                                                                        subPath={path}
                                                                        navLink={true}
                                                                        display={"flex"}
                                                                        p={"12px"}
                                                                    >
                                                                        {subTitle}
                                                                    </LocalizedLink>
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </PopoverContent>
                                                </>
                                            )}
                                        </Popover>
                                    ))}
                                </>
                            </HStack>
                        </UnorderedList>
                    </HStack>
                    <Flex alignItems={"center"} display={{base: "none", md: "flex"}}>
                        <HStack spacing={{md: "16px", lg: "32px"}}>
                            <HStack
                                spacing={"10px"}
                                borderRight="1px"
                                borderColor="custom.gray_300"
                                paddingRight={{md: "16px", lg: "32px"}}
                            >
                                <BiWalletAlt size={"18px"}/>
                                <NumberFormat
                                    value={Number(userInfo?.feeAmount) / 100}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"€ "}
                                    renderText={(formattedValue: string) => (
                                        <Text fontSize={"sm"} fontWeight={"medium"}>
                                            {formattedValue}
                                        </Text>
                                    )}
                                />
                            </HStack>
                            <Box>
                                <Menu placement={"bottom-end"} size={""}>
                                    {({isOpen}) => (
                                        <>
                                            <MenuButton>
                                                <HStack spacing={"12px"}>
                                                    <Avatar
                                                        name={userInfo?.firstName.startsWith('TRA') ? `${userInfo?.firstName}` : `${userInfo?.firstName} ${userInfo?.lastName}`}
                                                    />
                                                    <VStack
                                                        alignItems={"flex-start"}
                                                        spacing={"0"}
                                                        display={{md: "none", lg: "flex"}}
                                                    >
                                                        <Text>
                                                            {userInfo?.firstName.startsWith('TRA') ? `${userInfo?.firstName}` : `${userInfo?.firstName} ${userInfo?.lastName}`}
                                                        </Text>
                                                    </VStack>
                                                    {isOpen ? <FiChevronUp/> : <FiChevronDown/>}
                                                </HStack>
                                            </MenuButton>
                                            <MenuList>
                                                <MenuGroup title={t("navbar.languageDropdown.title")}>
                                                    <Box px={"1rem"} py={"0.5rem"}>
                                                        <LanguageDropdown/>
                                                    </Box>
                                                </MenuGroup>
                                                <MenuDivider/>
                                                <MenuGroup title={t("navbar.account.text")}>
                                                    <MenuItem onClick={() => dispatch(handleLogout())}>
                                                        {t("navbar.logout.text")}
                                                    </MenuItem>
                                                </MenuGroup>
                                            </MenuList>
                                        </>
                                    )}
                                </Menu>
                            </Box>
                        </HStack>
                    </Flex>
                    <Flex alignItems={"center"} display={{base: "flex", md: "none"}}>
                        <IconButton
                            variant="link"
                            minW={"auto"}
                            height={"auto"}
                            aria-label={"Open hamburger menu"}
                            icon={
                                isOpen ? (
                                    <FiX
                                        size={"18px"}
                                        style={{stroke: "black", strokeWidth: "2"}}
                                    />
                                ) : (
                                    <FiMenu
                                        size={"18px"}
                                        style={{stroke: "black", strokeWidth: "2"}}
                                    />
                                )
                            }
                            onClick={onToggle}
                        />
                    </Flex>
                </Flex>

                <Flex display={{base: "block", md: "none"}}>
                    {isOpen && (
                        <SlideFade
                            in={isOpen}
                            offsetY={"16px"}
                            style={{
                                position: "fixed",
                                left: 0,
                                right: 0,
                                zIndex: 9999,
                                padding: "0 16px",
                                backgroundColor: "white",
                                borderBottom: "1px solid #F2F4F7",
                                boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.05)",
                            }}
                        >
                            <List>
                                {NAV_ITEMS.filter(({active}) => active).map(({title, active, children}, index) => (
                                    <ListItem
                                        pt={4}
                                        pb={4}
                                        borderBottom={"1px"}
                                        borderColor={"custom.gray_200"}
                                        key={index}
                                    >
                                        <IsolatedMobileNavItem title={title} children={children} active={active}/>
                                    </ListItem>
                                ))}
                                <ListItem
                                    pt={4}
                                    pb={4}
                                    borderBottom={"1px"}
                                    borderColor={"custom.gray_200"}
                                >
                                    <HStack spacing={"10px"} paddingRight={"32px"}>
                                        <BiWalletAlt size={"18px"}/>
                                        <NumberFormat
                                            value={Number(userInfo?.feeAmount) / 100}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"€ "}
                                            renderText={(formattedValue: string) => (
                                                <Text fontSize={"sm"} fontWeight={"medium"}>
                                                    {formattedValue}
                                                </Text>
                                            )}
                                        />
                                    </HStack>
                                </ListItem>
                                <ListItem
                                    pt={4}
                                    pb={4}
                                    borderBottom={"1px"}
                                    borderColor={"custom.gray_200"}
                                >
                                    <Text mb={"4px"}>Taal</Text>
                                    <LanguageDropdown/>
                                </ListItem>
                                <ListItem pt={4} pb={4}>
                                    <Button
                                        onClick={() => dispatch(handleLogout())}
                                        variant={"secondary"}
                                    >
                                        Logout
                                    </Button>
                                </ListItem>
                            </List>
                        </SlideFade>
                    )}
                </Flex>
            </Container>
        </>
    );
};

export default Navbar;
