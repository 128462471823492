import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Circle,
  Container,
  Flex,
  HStack,
  Icon,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import AnimatedPage from "@components/animated-page";
import Table from "@components/table";
import { FiSettings, FiTrash2, FiFile } from "react-icons/fi";
import { CellProps, Column } from "react-table";
import useWindowDimensions from "@hooks/useWindowDimensions";
import { BREAKPOINT_MD, BREAKPOINT_LG } from "@utils/constants/breakpoints";
import Title from "@components/title";
import { useAppSelector } from "@store/hooks";
import { AppDispatch, RootState } from "@store/index";
import Spinner from "@components/spinner";
import { unwrapResult } from "@reduxjs/toolkit";
import { deletePrintJobs, getPrintJobs } from "@features/print/action";
import { useDispatch } from "react-redux";
import { printJobsRes, printJobsTable } from "@features/print/types";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import fileBlackWhite from "@assets/images/file-black-white.svg";
import fileColor from "@assets/images/file-color.svg";
import i18n from "i18next";
import useTitle from "@hooks/useTitle";

const MijnWachtrij = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoading, printJobs } = useAppSelector(
    (state: RootState) => state.print
  );
  const [sId, setSId] = useState<number | null>(null);
  const { width } = useWindowDimensions();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [selectedRows, setSelectedData] = useState<printJobsRes[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const lang = i18n.language;

  useTitle(t(("documentTitle.printManagement")))

  const onSelectedRows = (rows: printJobsRes[]) => {
    const mappedRows = rows.map((r) => r);
    if (!selectedRows) {
      setSelectedData([...selectedRows, ...mappedRows]);
    }
  };

  useEffect(() => {
    (async () => {
      const data = unwrapResult(await dispatch(getPrintJobs()));
      return printJobs.length ? data.filter((el: printJobsRes) => {
        return el.STATUS === 11 || el.STATUS === 21 || el.STATUS === 22;
      }) : [];
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const data: printJobsRes[] = useMemo(
    () =>
      printJobs.length ? printJobs.filter((el: printJobsRes) => {
        return el.STATUS === 11 || el.STATUS === 21 || el.STATUS === 22;
      }) : [],
    [printJobs]
  );

  const columns: Array<Column<printJobsTable>> = useMemo(
    () => [
      {
        Header: t("printManagement.myQueue.table.headingRow.file"),
        accessor: ({ DOCUMENT_NAME, PAGES }) => [DOCUMENT_NAME, PAGES],
        Cell: ({ value }: CellProps<printJobsRes>) => {
          const [DOCUMENT_NAME, PRINT_COPIES] = value;
          return (
            <>
              <HStack spacing={"12px"}>
                <Circle
                  size={"40px"}
                  bg={"custom.brand_primary"}
                  color={"custom.brand_primaryLight"}
                >
                  <Icon as={FiFile} />
                </Circle>
                <VStack alignItems={"flex-start"}>
                  <Text>{DOCUMENT_NAME}</Text>
                  <Text>
                    {PRINT_COPIES === 1
                      ? `${PRINT_COPIES} ${t(
                          "printManagement.myQueue.table.body.printCopies.page"
                        )}`
                      : `${PRINT_COPIES} ${t(
                          "printManagement.myQueue.table.body.printCopies.pages"
                        )}`}
                  </Text>
                </VStack>
              </HStack>
            </>
          );
        },
      },
      {
        Header: t("printManagement.myQueue.table.headingRow.type"),
        id: "PRINT_COLOR",
        accessor: ({ PRINT_COLOR }) => [PRINT_COLOR],
        Cell: ({ value }: CellProps<printJobsRes>) => {
          const [PRINT_COLOR] = value;
          return (
            <>
              <HStack spacing={"12px"}>
                <VStack alignItems={"flex-start"}>
                  <Text>
                    {PRINT_COLOR === 0
                      ? t("printManagement.myQueue.table.body.printColor.color")
                      : t(
                          "printManagement.myQueue.table.body.printColor.black-white"
                        )}
                  </Text>
                </VStack>
              </HStack>
            </>
          );
        },
        show: width > BREAKPOINT_MD,
      },
      {
        Header: t("printManagement.myQueue.table.headingRow.properties"),
        id: "eigenschappen",
        accessor: ({ PRINT_COLOR, PAGESIZE, PRINT_COPIES }) => [
          PRINT_COLOR,
          PAGESIZE,
          PRINT_COPIES,
        ],
        Cell: ({ value }: CellProps<printJobsRes>) => {
          const [PRINT_COLOR, PAGESIZE, PRINT_COPIES] = value;
          return (
            <>
              <HStack spacing={"12px"}>
                <VStack alignItems={"flex-start"}>
                  <HStack>
                    {PRINT_COLOR === 0 ? (
                      <>
                        <Image
                          src={fileColor}
                          alt={"file-color"}
                          maxW={"14px"}
                          w={"full"}
                        />
                      </>
                    ) : (
                      <>
                        <Image
                          src={fileBlackWhite}
                          alt={"file-black-white"}
                          maxW={"14px"}
                          w={"full"}
                        />
                      </>
                    )}
                    <Text>
                      {PAGESIZE === 0
                        ? t("printManagement.myQueue.table.body.pageSize.a4")
                        : t("printManagement.myQueue.table.body.pageSize.a3")}
                    </Text>
                  </HStack>
                  <Text>{`${PRINT_COPIES} ${
                    PRINT_COPIES === 1
                      ? t(
                          "printManagement.myQueue.table.body.printCopies.print"
                        )
                      : t(
                          "printManagement.myQueue.table.body.printCopies.prints"
                        )
                  }`}</Text>
                </VStack>
              </HStack>
            </>
          );
        },
        show: width < BREAKPOINT_MD,
      },
      {
        Header: t("printManagement.myQueue.table.headingRow.printFormat"),
        id: "afdrukformaat",
        accessor: ({ PAGESIZE }) => [PAGESIZE],
        Cell: ({ value }: CellProps<printJobsRes>) => {
          const [PAGESIZE] = value;
          return (
            <>
              <HStack spacing={"12px"}>
                <VStack alignItems={"flex-start"}>
                  <Text>
                    {PAGESIZE === 0
                      ? t("printManagement.myQueue.table.body.pageSize.a4")
                      : t("printManagement.myQueue.table.body.pageSize.a3")}
                  </Text>
                </VStack>
              </HStack>
            </>
          );
        },
        show: width > BREAKPOINT_MD,
      },
      {
        Header: t("printManagement.myQueue.table.headingRow.printCopies"),
        accessor: "PRINT_COPIES",
        show: width > BREAKPOINT_MD,
      },
      {
        Header: "Acties",
        accessor: "acties",
        Cell: ({ row: { original } }) => {
          return (
            <>
              <HStack spacing={"32px"}>
                <HStack>
                  <Link
                    onClick={() => {
                      navigate(`/${lang}${t("pageRoutes.beheer-print")}`, {
                        state: {
                          selected: [original],
                          managePrintsRedirectUrl: pathname,
                        },
                      });
                    }}
                  >
                    <Flex>
                      <Icon
                        as={FiSettings}
                        mr={"8px"}
                        width={"20px"}
                        height={"20px"}
                      />
                      {width > BREAKPOINT_LG
                        ? t(
                            "printManagement.myQueue.table.body.actions.managePrint.text"
                          )
                        : null}
                    </Flex>
                  </Link>
                </HStack>
                <HStack>
                  <Link
                    display={"flex"}
                    alignItems={"center"}
                    onClick={() => {
                      setSId(original.ID);
                      onOpen();
                    }}
                  >
                    <Icon
                      as={FiTrash2}
                      mr={"8px"}
                      width={"20px"}
                      height={"20px"}
                    />
                    {width > BREAKPOINT_LG
                      ? t(
                          "printManagement.myQueue.table.body.actions.deletePrint.text"
                        )
                      : null}
                  </Link>
                </HStack>
              </HStack>
            </>
          );
        },
        disableSortBy: true,
        show: width > BREAKPOINT_MD,
      },
    ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, width, navigate, pathname, onOpen]
  );

  if (isLoading) return <Spinner />;
  return (
    <>
      <Box>
        <Title title={t("printManagement.myQueue.title")} />
        <AnimatedPage animationType={"FadeInUp"}>
          <Container
            maxW={"container.xl"}
            p={{ base: "22px 16px", md: "45px 32px" }}
          >
            <Table
              data={data}
              columns={columns}
              onSelectedRows={onSelectedRows}
              managePrintsRedirectUrl={pathname}
              newPrintButton={true}
            />

            <Modal isOpen={isOpen} onClose={onClose} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                  {t(
                    "printManagement.myQueue.table.body.actions.deletePrint.modal.title"
                  )}
                </ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                  {t(
                    "printManagement.myQueue.table.body.actions.deletePrint.modal.description"
                  )}
                </ModalBody>

                <ModalFooter>
                  <Button mr={3} onClick={onClose}>
                    {t(
                      "printManagement.myQueue.table.body.actions.deletePrint.modal.cancelBtn"
                    )}
                  </Button>
                  <Button
                    variant="primary"
                    onClick={async () => {
                      dispatch(await deletePrintJobs({ ids: sId }));
                      await onClose();
                    }}
                  >
                    {t(
                      "printManagement.myQueue.table.body.actions.deletePrint.modal.confirmBtn"
                    )}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Container>
        </AnimatedPage>
      </Box>
    </>
  );
};

export default MijnWachtrij;
