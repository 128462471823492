import {createAsyncThunk} from "@reduxjs/toolkit";
import {http} from "@utils/http";

export const getWebsiteColors = createAsyncThunk(
    "byod/website-colors",
    async (_, {rejectWithValue}) => {
        try {
            const res = await http.get("/byod/website-colors/");
            return res.data;
        } catch (error) {
            return rejectWithValue({error});
        }
    }
);
