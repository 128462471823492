import React from "react";
import metadata from "@src/metadata.json";


const Index = () => {
    return (
        <footer>
            {`Versie: ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision}`}
        </footer>
    );
};

export default Index;
