import React, {ChangeEvent, useEffect} from "react";
import {
    Box,
    Button,
    chakra,
    Checkbox,
    Flex,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useDisclosure,
} from "@chakra-ui/react";
import {
    useTable,
    useGlobalFilter,
    useSortBy,
    Column,
    usePagination,
    useRowSelect,
    Row,
} from "react-table";
import {FiArrowUp, FiArrowDown} from "react-icons/fi";
import SearchBox from "../search-box";
import Pagination from "../pagination";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {BREAKPOINT_MD} from "@utils/constants/breakpoints";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {deletePrintJobs, updatePrintJob} from "@features/print/action";
import {useDispatch} from "react-redux";
import {AppDispatch} from "@store/index";
import i18n from "i18next";

interface TableProps {
    columns: Array<Column<any>>;
    data: Array<any>;
    onSelectedRows: (rows: any) => void;
    selectedRows?: Row<any>;
    managePrintsRedirectUrl: string;
    newPrintButton?: boolean;
}

const IndeterminateCheckbox = ({showSelectLabel, ...rest}: any) => {
    return (
        <>
            <Checkbox
                isIndeterminate={rest.indeterminate}
                isChecked={rest.checked}
                onChange={rest.onChange}
            >
                {
                    showSelectLabel &&
                    rest.checked ? "Alles deselecteren" : showSelectLabel &&
                    !rest.checked ? "Alles selecteren" : null
                }
            </Checkbox>
        </>
    );
};

const Index = ({
                   onSelectedRows,
                   managePrintsRedirectUrl,
                   newPrintButton,
                   data,
                   columns,
               }: TableProps) => {
    const {width} = useWindowDimensions();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const dispatch = useDispatch<AppDispatch>();
    const {isOpen, onOpen, onClose} = useDisclosure();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        gotoPage,
        pageCount,
        setPageSize,
        selectedFlatRows,
        state: {pageIndex, pageSize, selectedRowIds, globalFilter},
        setGlobalFilter,
    } = useTable(
        {
            columns,
            data,
            disableSortBy: width < BREAKPOINT_MD,
            initialState: {
                pageSize: 20,
                hiddenColumns: columns
                    .filter((col: any) => col.show === false)
                    .map((col) => col.id || col.accessor) as any,
            },
            autoResetSelectedRows: false,
            autoResetSelectedCell: false,
            autoResetSelectedColumn: false,
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                // Let's make a column for selection
                {
                    id: "selection",
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox
                    Header: ({getToggleAllRowsSelectedProps}) => (
                        <HStack spacing={'12px'} >
                            <IndeterminateCheckbox showSelectLabel={true} {...getToggleAllRowsSelectedProps()} />
                        </HStack>
                    ),
                    style: "padding: 0",
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({row}) => (
                        <Box>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </Box>
                    ),
                },
                ...columns,
            ]);
        }
    );

    const lang = i18n.language;

    useEffect(() => {
        onSelectedRows(selectedFlatRows);
    }, [selectedFlatRows, onSelectedRows]);

    const isFirstIndex = (x: number) => {
        return x === 0 ? "0" : "var(--chakra-space-4) var(--chakra-space-6)";
    };

    const handlePageSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setPageSize(Number(e.target.value));
    };

    let selected = selectedFlatRows.map((d: any) => d.original);

    const handleReprint = () => {
        selected.forEach(({ID}) => {
            selected = [{ID: ID, STATUS: 11}];
        });

        new Promise((resolve, reject) => {
            dispatch(
                updatePrintJob({
                    body: selected,
                })
            )
                .then(() => resolve(navigate(`/${lang}/${t("router.mijn-wachtrij")}`)))
                .catch((err) => reject(err));
        });
    };

    return (
        <>
            <SearchBox filter={globalFilter} setFilter={setGlobalFilter}/>

            <Flex mb={"45px"} justifyContent={newPrintButton ? 'space-between' : 'flex-end'}>
                { newPrintButton && <Button
                    variant={"primary"}
                    onClick={() => {
                       navigate(`/${lang}${t("pageRoutes.nieuwe-prints")}`);
                    }}
                >
                    {t("table.topbar.addPrint")}
                </Button>}

                <HStack spacing={"12px"}>
                    {data.find((item) => item.STATUS === 3) && (
                        <Button
                            variant={"outline"}
                            isDisabled={
                                Object.getOwnPropertyNames(selectedRowIds).length === 0
                            }
                            onClick={handleReprint}
                        >
                            {t("table.topbar.reprint")}
                        </Button>
                    )}

                    {Object.getOwnPropertyNames(selectedRowIds).length > 1 && (
                        <Button
                            variant={"outline"}
                            isDisabled={Object.getOwnPropertyNames(selectedRowIds).length < 1}
                            onClick={() => {
                                navigate(`/${lang}${t("pageRoutes.beheer-print")}`, {
                                    state: {
                                        selected: selected,
                                        managePrintsRedirectUrl: managePrintsRedirectUrl,
                                    },
                                });
                            }}
                        >
                            {t("table.topbar.managePrints", {
                                amount: Object.getOwnPropertyNames(selectedRowIds).length,
                            })}
                        </Button>
                    )}

                    {Object.getOwnPropertyNames(selectedRowIds).length > 1 && (
                        <>
                            <Button
                                variant={"outline"}
                                isDisabled={
                                    Object.getOwnPropertyNames(selectedRowIds).length < 1
                                }
                                onClick={onOpen}
                            >
                                {t("table.topbar.deletePrints.text", {
                                    amount: Object.getOwnPropertyNames(selectedRowIds).length,
                                })}
                            </Button>

                            {isOpen && (
                                <Modal isOpen={isOpen} onClose={onClose} isCentered>
                                    <ModalOverlay/>
                                    <ModalContent>
                                        <ModalHeader>
                                            {t("table.topbar.deletePrints.modal.title")}
                                        </ModalHeader>
                                        <ModalCloseButton/>

                                        <ModalBody>
                                            {t("table.topbar.deletePrints.modal.description")}
                                        </ModalBody>

                                        <ModalFooter>
                                            <Button mr={3} onClick={onClose}>
                                                {t("table.topbar.deletePrints.modal.cancelBtn")}
                                            </Button>
                                            <Button
                                                variant="primary"
                                                onClick={async () => {
                                                    dispatch(
                                                        await deletePrintJobs({
                                                            ids: selectedFlatRows.map(
                                                                (d: any) => d.original.ID
                                                            ),
                                                        })
                                                    );
                                                }}
                                            >
                                                {t("table.topbar.deletePrints.modal.confirmBtn")}
                                            </Button>
                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>
                            )}
                        </>
                    )}

                    {Object.getOwnPropertyNames(selectedRowIds).length === 1 && (
                        <Button
                            variant={"outline"}
                            isDisabled={
                                Object.getOwnPropertyNames(selectedRowIds).length === 0
                            }
                            onClick={() => {
                                navigate(`/${lang}${t("pageRoutes.beheer-print")}`, {
                                    state: {
                                        selected: selected,
                                        managePrintsRedirectUrl: managePrintsRedirectUrl,
                                    },
                                });
                            }}
                        >
                            {t("table.topbar.managePrint")}
                        </Button>
                    )}

                    {Object.getOwnPropertyNames(selectedRowIds).length === 1 && (
                        <>
                            <Button
                                variant={"outline"}
                                isDisabled={
                                    Object.getOwnPropertyNames(selectedRowIds).length === 0
                                }
                                onClick={onOpen}
                            >
                                {t("table.topbar.deletePrint.text")}
                            </Button>

                            {isOpen && (
                                <Modal isOpen={isOpen} onClose={onClose} isCentered>
                                    <ModalOverlay/>
                                    <ModalContent>
                                        <ModalHeader>
                                            {t("table.topbar.deletePrints.modal.title")}
                                        </ModalHeader>
                                        <ModalCloseButton/>

                                        <ModalBody>
                                            {t("table.topbar.deletePrints.modal.description")}
                                        </ModalBody>

                                        <ModalFooter>
                                            <Button mr={3} onClick={onClose}>
                                                {t("table.topbar.deletePrints.modal.cancelBtn")}
                                            </Button>
                                            <Button
                                                variant="primary"
                                                onClick={async () => {
                                                    dispatch(
                                                        await deletePrintJobs({
                                                            ids: selectedFlatRows.map(
                                                                (d: any) => d.original.ID
                                                            ),
                                                        })
                                                    );
                                                }}
                                            >
                                                {t("table.topbar.deletePrints.modal.confirmBtn")}
                                            </Button>
                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>
                            )}
                        </>
                    )}

                    <Select
                        variant={"black"}
                        width={"auto"}
                        onChange={handlePageSizeChange}
                    >
                        {[20, 50, 100].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {t("table.topbar.showItems", {amount: pageSize})}
                            </option>
                        ))}
                    </Select>
                </HStack>
            </Flex>
            <TableContainer mb={"45px"} p={"0 3px"}>
                <Table {...getTableProps()} mb={"15px"}>
                    <Thead>
                        {headerGroups.map((headerGroup) => (
                            <Tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column, i) => (
                                    <Th
                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                        padding={isFirstIndex(i)}
                                    >
                                        {i === 0 ? (
                                            column.render("Header")
                                        ) : (
                                            <Flex alignItems={"center"}>
                                                {column.render("Header")}
                                                <chakra.span pl={"4px"}>
                                                    {column.isSorted && width > BREAKPOINT_MD ? (
                                                        column.isSortedDesc ? (
                                                            <FiArrowDown aria-label="Sorted descending"/>
                                                        ) : (
                                                            <FiArrowUp aria-label="Sorted ascending"/>
                                                        )
                                                    ) : null}
                                                </chakra.span>
                                            </Flex>
                                        )}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            prepareRow(row);
                            return (
                                <Tr {...row.getRowProps()}>
                                    {row.cells.map((cell, i) => (
                                        <Td {...cell.getCellProps()} padding={isFirstIndex(i)}>
                                            {cell.render("Cell")}
                                        </Td>
                                    ))}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </TableContainer>

            {pageCount > 1 ? (
                <Flex justifyContent={"center"}>
                    <Pagination
                        className="pagination-bar"
                        currentPage={pageIndex + 1}
                        totalCount={data.length}
                        pageSize={pageSize}
                        onPageChange={(page) => gotoPage(page - 1)}
                        siblingCount={2}
                    />
                </Flex>
            ) : null}
        </>
    );
};

export default Index;
