import * as React from "react";
import {useDispatch} from "react-redux";
import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Button,
    Heading,
    VStack,
    Image,
    FormErrorMessage,
    HStack,
} from "@chakra-ui/react";
import * as yup from "yup";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import logo from "@assets/images/logo.svg";
import LocalizedLink from "@components/localized-link";
import {AppDispatch} from "@src/store";
import {LoginRequestI} from "@features/auth/types";
import {useTranslation} from "react-i18next";
import LanguageDropdown from "@components/language-dropdown";
import {reservationSignin} from "@features/reservation/action";
import {useNavigate, useParams} from "react-router-dom";
import i18n from "i18next";
import {toast} from "react-toastify";
import useTitle from "@hooks/useTitle";

const Login = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {t} = useTranslation();
    const lang = i18n.language;
    const navigate = useNavigate();
    const {objectName} = useParams();

    useTitle(t(("documentTitle.auth")))

    const schema = yup.object({
        userLogonId: yup.string().required("Lidkaartnummer is verplicht")
    });

    const {
        control,
        handleSubmit,
        formState: {errors},
    } = useForm<LoginRequestI>({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            userLogonId: "",
        },
    });

    return (
        <Flex
            height={"100vh"}
            position={"relative"}
            overflow={"hidden"}
            align={"center"}
            justifyContent={"center"}
            bg={"custom.brand_primaryDark"}
        >
            <Box
                pos={"absolute"}
                top={"-30vw"}
                left={"-20vw"}
                w={"50vw"}
                h={"50vw"}
                transform={"rotate(45deg)"}
                bg={"custom.brand_loginBlock"}
                borderRadius={"10vw"}
            />
            <Flex
                pos={"relative"}
                zIndex={"1"}
                flexDir={"column"}
                w={"480px"}
                mx={{base: "16px", md: "0"}}
                p={{base: "32px", sm: "45px", md: "60px"}}
                bg={"custom.gray_50"}
            >
                <VStack align={"flex-start"} spacing={"40px"}>
                    <Box position={"absolute"} top={"20px"} right={"20px"} w={"auto"}>
                        <LanguageDropdown/>
                    </Box>
                    <LocalizedLink>
                        <Image
                            src={logo}
                            alt={"logo"}
                            maxW={{base: "220px", md: "260px"}}
                            w={"full"}
                        />
                    </LocalizedLink>

                    <VStack
                        as={"form"}
                        onSubmit={handleSubmit((data: LoginRequestI) => {
                                return new Promise((resolve, reject) => {
                                    dispatch(reservationSignin({
                                            ObjectName: objectName,
                                            userLogonId: data.userLogonId
                                        })
                                    )
                                        .then((res: any) => {
                                            console.log(res)
                                            if (res?.payload.data.error) {
                                                toast.error(res?.payload.data.error);
                                            } else {
                                                toast.success(i18n.t("reservationSignin.successMsg"));
                                                resolve(
                                                    navigate(`/${lang}/${t("router.login")}`)
                                                )
                                            }
                                        }
                                        )
                                        .catch((err) => {
                                            console.log(err)
                                            reject(err)
                                        });
                                });
                            }
                        )}
                        w={"full"}
                        noValidate
                    >
                        <VStack w="full" align={"flex-start"} spacing={"32px"}>
                            <Heading
                                fontSize={{base: "xl", md: "2xl"}}
                                fontWeight={"semibold"}
                                color={"black"}
                            >
                                {t("loginWorkPlace.title")}
                            </Heading>
                            <VStack w={"full"} spacing={"20px"}>
                                <FormControl isInvalid={!!errors?.userLogonId} isRequired>
                                    <FormLabel htmlFor={"userLogonId"}>
                                        {t("auth.login.membershipCardNumber")}
                                    </FormLabel>
                                    <Controller
                                        name={"userLogonId"}
                                        control={control}
                                        render={({field}) => (
                                            <Input
                                                {...field}
                                                variant={"filled"}
                                                id={"lidkaartnummer"}
                                                placeholder={t("loginWorkPlace.membershipCardNumber.placeholder")}
                                            />
                                        )}
                                    />
                                    <FormErrorMessage>
                                        {!!errors?.userLogonId && errors?.userLogonId?.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </VStack>
                            <HStack spacing={"12px"} w={"full"}>
                                <Button type={"submit"} w={"full"} variant={"primary"}>
                                    {t("auth.login.confirmBtn")}
                                </Button>
                            </HStack>
                        </VStack>
                    </VStack>
                </VStack>
            </Flex>
            <Box
                pos={"absolute"}
                bottom={"-30vw"}
                right={"-20vw"}
                w={"50vw"}
                h={"50vw"}
                transform={"rotate(45deg)"}
                bg={"custom.brand_loginBlock"}
                borderRadius={"10vw"}
            />
        </Flex>
    );
};

export default Login;
