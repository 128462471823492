import React from "react";
import {
  useRadioGroup,
  HStack,
  FormControl,
  FormErrorMessage,
  FormLabel,
  VStack,
} from "@chakra-ui/react";
import { useController } from "react-hook-form";
import RadioCard from "./radio-card";

interface OptionsI {
  value: string;
  label: string;
}

interface RadioGroupProps {
  options: OptionsI[];
  control: any;
  name: string;
  label?: string;
}

const RadioGroup = ({ options, control, name, label }: RadioGroupProps) => {
  const {
    field,
    formState: { errors },
  } = useController({
    control,
    name,
    rules: { required: { value: true, message: "Required field" } },
  });
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange: field.onChange,
    value: field.value,
  });

  const group = getRootProps();

  return (
    <FormControl
      display={"flex"}
      flexDir={{ base: "column", lg: "row" }}
      alignItems={{ base: "flex-start", lg: "center" }}
      justifyContent={"space-between"}
      isInvalid={!!errors[name]}
      isRequired
    >
      {label && <FormLabel mb={{ base: "6px", lg: "0" }}>{label}</FormLabel>}
      <VStack>
        <HStack
          {...group}
          alignItems={{ base: "flexStart", md: "center" }}
          flexWrap={"wrap"}
          justifyContent={{
            base: "flex-start",
            lg: label ? "flex-end" : "flex-start",
          }}
          spacing={0}
        >
          {options.map(({ value, label }) => {
            const radio = getRadioProps({ value });
            return (
              <RadioCard key={value} {...radio} value={value}>
                {label}
              </RadioCard>
            );
          })}
        </HStack>
        <FormErrorMessage>
          {errors[name] && errors[name]?.message}
        </FormErrorMessage>
      </VStack>
    </FormControl>
  );
};

export default RadioGroup;
