import { createSlice } from "@reduxjs/toolkit";
import {getWebsiteColors} from "@features/colors/action";
import {websiteColorsI} from "@features/colors/types";

const initialState: websiteColorsI = {
    loading: false,
    websiteColors: [],
    error: null,
};

export const websiteColorsSlice = createSlice({
    name: "websiteColors",
    initialState,
    reducers: {
        reset: (state) => {
            state = initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getWebsiteColors.pending, (state) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getWebsiteColors.fulfilled, (state, action) => {
            state.loading = false;
            state.websiteColors = action.payload
        });
        builder.addCase(getWebsiteColors.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default websiteColorsSlice.reducer;
