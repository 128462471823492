import React, { useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import RadioGroup from "../radio-group";
import { formValues } from "../../types/form";
import { useTranslation } from "react-i18next";

const Index = ({ onSubmit, currentSettings }: any) => {
  const { t } = useTranslation();

  const PRINT_COLOR = [
    { value: "0", label: `${t("printPreferences.printColor.color")}` },
    { value: "1", label: `${t("printPreferences.printColor.black-white")}` },
  ];
  const PAGESIZE = [
    { value: "0", label: `${t("printPreferences.pageSize.a4")}` },
    { value: "1", label: `${t("printPreferences.pageSize.a3")}` },
  ];
  const PRINT_ORIENTATION = [
    { value: "0", label: `${t("printPreferences.printOrientation.portrait")}` },
    {
      value: "1",
      label: `${t("printPreferences.printOrientation.landscape")}`,
    },
  ];
  const PRINT_DUPLEX = [
    { value: "0", label: `${t("printPreferences.printDuplex.oneSided")}` },
    { value: "1", label: `${t("printPreferences.printDuplex.twoSided")}` },
  ];

  const schema = yup.object().shape({
    PRINT_COLOR: yup.string().required("PRINT_COLOR is verplicht"),
    PAGESIZE: yup.string().required("PAGESIZE is verplicht"),
    PRINT_ORIENTATION: yup.string().required("PRINT_ORIENTATION is verplicht"),
    PRINT_DUPLEX: yup.string().required("PRINT_DUPLEX is verplicht"),
    PRINT_COPIES: yup.string().required("PRINT_COPIES is verplicht"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<formValues>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      PRINT_COLOR: "1",
      PAGESIZE: "0",
      PRINT_ORIENTATION: "0",
      PRINT_DUPLEX: "0",
      PRINT_COPIES: "1",
    },
  });

  const allEqual = (arr: [], key: string) => {
    let firstNonNull: null = null;
    return arr.every((v) => {
      const value = v[key];
      if (value === null) {
        return true;
      }
      if (firstNonNull === null) {
        firstNonNull = value;
        return true;
      }
      return value === firstNonNull;
    });
  };

  useEffect(() => {
    let defaults;

    if (currentSettings?.length) {
      defaults = {
        PRINT_COLOR: allEqual(currentSettings, "PRINT_COLOR")
          ? currentSettings[0].PRINT_COLOR.toString()
          : "",
        PAGESIZE: allEqual(currentSettings, "PAGESIZE")
          ? currentSettings[0].PAGESIZE.toString()
          : "",
        PRINT_ORIENTATION: allEqual(currentSettings, "PRINT_ORIENTATION")
          ? currentSettings[0].PRINT_ORIENTATION.toString()
          : "",
        PRINT_DUPLEX: allEqual(currentSettings, "PRINT_DUPLEX")
          ? currentSettings[0].PRINT_DUPLEX.toString()
          : "",
        PRINT_COPIES: allEqual(currentSettings, "PRINT_COPIES")
          ? currentSettings[0].PRINT_COPIES.toString()
          : "",
      };

      reset(defaults, { keepDirtyValues: true });
    }
  }, [reset, currentSettings]);

  return (
    <Box
      p={{ base: "20px", md: "32px", lg: "45px" }}
      border={"1px"}
      bg={"custom.gray_25"}
      borderColor={"custom.gray_300"}
    >
      <VStack
        as={"form"}
        onSubmit={handleSubmit(onSubmit)}
        w={"full"}
        noValidate
      >
        <Stack alignItems={"flex-start"} spacing={"22px"}>
          <VStack w={"full"} alignItems={"stretch"} spacing={"16px"}>
            <RadioGroup
              options={PRINT_COLOR}
              name="PRINT_COLOR"
              label={t("printPreferences.printColor.title")}
              control={control}
            />
            <Divider />
            <RadioGroup
              options={PAGESIZE}
              name="PAGESIZE"
              label={t("printPreferences.pageSize.title")}
              control={control}
            />
            <Divider />
            <RadioGroup
              options={PRINT_ORIENTATION}
              name="PRINT_ORIENTATION"
              label={t("printPreferences.printOrientation.title")}
              control={control}
            />
            <Divider />
            <RadioGroup
              options={PRINT_DUPLEX}
              name="PRINT_DUPLEX"
              label={t("printPreferences.printDuplex.title")}
              control={control}
            />
            <Divider />
            <Flex
              justifyContent={"space-between"}
              alignItems={{ base: "flex-start", lg: "center" }}
              flexDir={{ base: "column", lg: "row" }}
            >
              <FormControl isInvalid={!!errors?.PRINT_COPIES} isRequired>
                <Grid templateColumns="repeat(4, 1fr)">
                  <GridItem colSpan={{ base: 4, lg: 2 }} alignItems={"center"}>
                    <Flex alignItems={"center"} height={"100%"}>
                      <FormLabel
                        htmlFor={"aantal-kopies"}
                        mb={{ base: "6px", lg: "0" }}
                      >
                        {t("printPreferences.amountOfCopies.title")}
                      </FormLabel>
                    </Flex>
                  </GridItem>
                  <GridItem colSpan={{ base: 4, lg: 2 }}>
                    <Controller
                      name={"PRINT_COPIES"}
                      control={control}
                      render={({ field }) => (
                        <NumberInput {...field} variant={"filled"}>
                          <NumberInputField
                            id={"print-copies"}
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                          />
                        </NumberInput>
                      )}
                    />
                  </GridItem>
                  <GridItem
                    colStart={{ base: 1, lg: 3 }}
                    colSpan={{ base: 4, lg: 2 }}
                  >
                    <FormErrorMessage mt={"0"} pt={"8px"}>
                      {!!errors?.PRINT_COPIES && errors?.PRINT_COPIES?.message}
                    </FormErrorMessage>
                  </GridItem>
                </Grid>
              </FormControl>
            </Flex>
            <Divider />
          </VStack>
          <Alert status={"warning"} p={"24px"}>
            <VStack alignItems={"flex-start"}>
              <Heading as={"h3"} size={"18px"}>
                {t("printPreferences.attention.title")}
              </Heading>
              <Text>{t("printPreferences.attention.description")}</Text>
            </VStack>
          </Alert>
          <Button variant={"primary"} type={"submit"} disabled={!isValid}>
            {t("printPreferences.confirmBtn.title")}
          </Button>
        </Stack>
      </VStack>
    </Box>
  );
};

export default Index;
