import * as React from "react";
import {useDispatch} from "react-redux";
import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Button,
    Heading,
    VStack,
    Image,
    FormErrorMessage,
    HStack,
} from "@chakra-ui/react";
import * as yup from "yup";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import logo from "@assets/images/logo.svg";
import LocalizedLink from "@components/localized-link";
import {AppDispatch, RootState} from "@src/store";
import {LoginRequestI} from "@features/auth/types";
import {handleLogin} from "@features/auth/action";
import {ChangeEvent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "@store/hooks";
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import LanguageDropdown from "@components/language-dropdown";
import useTitle from "@hooks/useTitle";

const Login = () => {
    const [membershipValue, setMembershipValue] = useState("");
    const dispatch = useDispatch<AppDispatch>();
    const {userToken} = useAppSelector((state: RootState) => state.auth);
    const {t} = useTranslation();
    const lang = i18n.language;
    const navigate = useNavigate();

    const {userSettings} = useAppSelector((state: RootState) => state.auth);
    const filteredUserSettingsArr = userSettings && Object.keys(userSettings).filter(key => userSettings[key] && key !== '_id' && key !== 'sessionToken');

    const schema = yup.object({
        userLogonId: yup.string().required("Lidkaartnummer is verplicht"),
        userFirstName: yup.string().when(['userLogonId'], (userLogonId) => {
            if (!userLogonId.startsWith('TRA')) {
                return yup.string().required("Vooraam is verplicht")
            } else {
                return yup.string().notRequired();
            }
        }),
        userName: yup.string().when(['userLogonId'], (userLogonId) => {
            if (!userLogonId.startsWith('TRA')) {
                return yup.string().required('Naam is verplicht')
            } else {
                return yup.string().notRequired();
            }
        }),
    });

    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: {errors},
    } = useForm<LoginRequestI>({
        mode: "all",
        resolver: yupResolver(schema),
        defaultValues: {
            userLogonId: "",
            userName: "",
            userFirstName: "",
        },
    });

    useTitle(t(("documentTitle.auth")))

    useEffect(() => {
        if (userToken && filteredUserSettingsArr.length > 1) {
            navigate(`/${lang}/${t("router.choose-category")}`);
        } else if (userToken && filteredUserSettingsArr.length === 1) {
            if (filteredUserSettingsArr.length === 1 && userSettings?.BYODAvailable) {
                navigate(`/${lang}/${t("router.nieuwe-prints")}`);
            } else if (filteredUserSettingsArr.length === 1 && userSettings?.reservePCAvailable) {
                navigate(`/${lang}/${t("router.create-pc-reservation")}`);
            } else if (filteredUserSettingsArr.length === 1 && userSettings?.reservePlaceAvailable) {
                navigate(`/${lang}/${t("router.create-workplace-reservation")}`);
            }
        }
    }, [userToken, lang, navigate, t]);

    const handleMembershipChange = (e: ChangeEvent) => {
        const regex = /^[a-zA-Z0-9_-]*$/;
        const element = e.target as HTMLInputElement;
        if (element.value === "" || regex.test(element.value)) {
            setMembershipValue(element.value);
            reset({
                userLogonId: element.value,
            });
        }
    };

    // This is only for development reasons
    const addQuickLoginData = () => {
        setMembershipValue("1000");
        reset({
            userLogonId: "1000",
            userFirstName: "(Sjoerd)",
            userName: "Test",
        });
    };


    const logonID = getValues("userLogonId")

    return (
        <Flex
            height={"100vh"}
            position={"relative"}
            overflow={"hidden"}
            align={"center"}
            justifyContent={"center"}
            bg={"custom.brand_primaryDark"}
        >
            <Box
                pos={"absolute"}
                top={"-30vw"}
                left={"-20vw"}
                w={"50vw"}
                h={"50vw"}
                transform={"rotate(45deg)"}
                bg={"custom.brand_loginBlock"}
                borderRadius={"10vw"}
            />
            <Flex
                pos={"relative"}
                zIndex={"1"}
                flexDir={"column"}
                w={"480px"}
                mx={{base: "16px", md: "0"}}
                p={{base: "80px 32px 32px", sm: "45px", md: "60px"}}
                bg={"custom.gray_50"}
            >
                <VStack align={"flex-start"} spacing={"40px"}>
                    <Box position={"absolute"} top={"20px"} right={"20px"} w={"auto"}>
                        <LanguageDropdown />
                    </Box>
                    <LocalizedLink display={"block"} w={"100%"} maxW={{base: "220px", md: "260px"}}>
                        <Image
                            src={logo}
                            alt={"logo"}
                            w={"full"}
                        />
                    </LocalizedLink>

                    <VStack
                        as={"form"}
                        onSubmit={handleSubmit((data: LoginRequestI) =>
                            dispatch(handleLogin({
                                ...data,
                                "userLanguage": lang.toUpperCase()
                            }))
                        )}
                        w={"full"}
                        noValidate
                    >
                        <VStack w="full" align={"flex-start"} spacing={"32px"}>
                            <Heading
                                fontSize={{base: "xl", md: "2xl"}}
                                fontWeight={"semibold"}
                                color={"black"}
                            >
                                {t("auth.login.title")}
                            </Heading>
                            <VStack w={"full"} spacing={"20px"}>
                                <FormControl isInvalid={!!errors?.userLogonId} isRequired>
                                    <FormLabel htmlFor={"userLogonId"}>
                                        {t("auth.login.membershipCardNumber")}
                                    </FormLabel>
                                    <Controller
                                        name={"userLogonId"}
                                        control={control}
                                        render={({field}) => (
                                            <Input
                                                {...field}
                                                variant={"filled"}
                                                id={"lidkaartnummer"}
                                                placeholder={t("auth.login.membershipCardNumber")}
                                                value={membershipValue}
                                                onChange={handleMembershipChange}
                                            />
                                        )}
                                    />
                                    <FormErrorMessage>
                                        {!!errors?.userLogonId && errors?.userLogonId?.message}
                                    </FormErrorMessage>
                                </FormControl>

                                <FormControl isInvalid={!logonID.startsWith("TRA") && !!errors?.userFirstName}
                                             isRequired={!logonID.startsWith("TRA")}>
                                    <FormLabel htmlFor={"userFirstName"}>
                                        {t("auth.login.firstName")}
                                    </FormLabel>
                                    <Controller
                                        name={"userFirstName"}
                                        control={control}
                                        render={({field}) => (
                                            <Input
                                                {...field}
                                                id={"voornaam"}
                                                variant={"filled"}
                                                placeholder={t("auth.login.firstName")}
                                            />
                                        )}
                                    />
                                    <FormErrorMessage>
                                        {!logonID.startsWith("TRA") && !!errors?.userFirstName && errors?.userFirstName?.message}
                                    </FormErrorMessage>
                                </FormControl>
                                <FormControl isInvalid={!logonID.startsWith("TRA") && !!errors?.userName}
                                             isRequired={!logonID.startsWith("TRA")}>
                                    <FormLabel htmlFor={"userName"}>
                                        {t("auth.login.lastName")}
                                    </FormLabel>
                                    <Controller
                                        name={"userName"}
                                        control={control}
                                        render={({field}) => (
                                            <Input
                                                {...field}
                                                id={"naam"}
                                                variant={"filled"}
                                                placeholder={t("auth.login.lastName")}
                                            />
                                        )}
                                    />
                                    <FormErrorMessage>
                                        {!logonID.startsWith("TRA") && !!errors?.userName && errors?.userName?.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </VStack>
                            <HStack spacing={"12px"} w={"full"}>
                                {process.env.NODE_ENV !== "production" && (
                                    <Button
                                        w={"full"}
                                        variant={"secondary"}
                                        onClick={addQuickLoginData}
                                    >
                                        Prefill login
                                    </Button>
                                )}
                                <Button type={"submit"} w={"full"} variant={"primary"}>
                                    {t("auth.login.confirmBtn")}
                                </Button>
                            </HStack>
                        </VStack>
                    </VStack>
                </VStack>
            </Flex>
            <Box
                pos={"absolute"}
                bottom={"-30vw"}
                right={"-20vw"}
                w={"50vw"}
                h={"50vw"}
                transform={"rotate(45deg)"}
                bg={"custom.brand_loginBlock"}
                borderRadius={"10vw"}
            />
        </Flex>
    );
};

export default Login;
