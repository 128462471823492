import React from "react";
import {Box, Text} from "@chakra-ui/react";
import TimeRange from 'react-timeline-range-slider'
import {useTranslation} from "react-i18next";
import moment from "moment";

const Index = ({
                   isSelectedInterval,
                   setSelectedInterval,
                   selectedInterval,
                   selectedStartEnd,
                   disabledIntervals,
                   timelineInterval,
                   ticksNumber,
                   error,
                   setError,
                   selectedDate,
                   selectedStartTime,
                   selectedEndTime
               }: any) => {

    const errorHandler = ({error}: any) => setError(error);
    const {t} = useTranslation();

    const onChangeCallback = (selectedInterval: any) => {
        setSelectedInterval(selectedInterval);
    };

    return (
        <>
            <TimeRange
                containerClassName={`time-range-slider ${error && "time-range-slider-error"}`}
                error={error}
                step={900000}
                ticksNumber={ticksNumber}
                onUpdateCallback={errorHandler}
                onChangeCallback={onChangeCallback}
                selectedInterval={selectedInterval ? selectedInterval : []}
                timelineInterval={timelineInterval}
                disabledIntervals={disabledIntervals ? disabledIntervals : []}
            />

            {isSelectedInterval && (
                <Box>
                    <Text as={"span"} fontSize={"12px"}>
                        {t('newReservation.timeslotSelected')}
                    </Text>{" "}
                    <Text as={"span"} fontSize={"12px"}>
                        {`${moment(selectedDate).format("DD-MM-yyyy")}, ${selectedStartTime} - ${moment(selectedDate).format("DD-MM-yyyy")}, ${selectedEndTime}`}
                    </Text>
                </Box>
            )}
        </>
    );
};

export default Index;
