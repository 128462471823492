import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import nl from "@assets/i18n/nl.json";
import fr from "@assets/i18n/fr.json";
import de from "@assets/i18n/de.json";
import en from "@assets/i18n/en.json";

const resources = {
    nl,
    fr,
    de,
    en,
};

export const availableLanguages = Object.keys(resources);

const supportedLanguages = ["nl", "fr", "de", "en"];


const options = {
    order: ["path", "navigator"],
    checkWhitelist: true,
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs: supportedLanguages,
        fallbackLng: "nl",
        interpolation: {
            escapeValue: false,
        },
        cleanCode: true,
        resources,
        ns: ["common"],
        defaultNS: "common",
        detection: options,
    });

export default i18n;
