import * as React from "react";
import {
  Box,
  Circle,
  Container,
  Heading,
  Icon,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FiFile } from "react-icons/fi";
import AnimatedPage from "@components/animated-page";
import Title from "@components/title";
import { useAppSelector } from "@store/hooks";
import { AppDispatch, RootState } from "@store/index";
import Spinner from "@components/spinner";
import PrintPreferences from "@components/print-preferences";
import { getPrintJobsByIds, updatePrintJob } from "@features/print/action";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { formValues } from "../../types/form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { revertAll } from "@features/upload/action";
import useTitle from "@hooks/useTitle";

const BevestigUpload = () => {
  const { isLoading } = useAppSelector((state: RootState) => state.user);
  const { upload } = useAppSelector((state: RootState) => state.upload);
  const { printJobsByIds } = useAppSelector((state: RootState) => state.print);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = i18n.language;

  useTitle(t(("documentTitle.printManagement")))

  useEffect(() => {
    if (upload?.length) {
      (async () => {
        return unwrapResult(
          await dispatch(
            getPrintJobsByIds({
              id: Object.values(upload?.map(({ ID }) => ID)).join(","),
            })
          )
        );
      })();
    }

    return () => {
      dispatch(revertAll());
    };
  }, [dispatch, lang, navigate, t, upload]);

  const handleSubmit = (values: formValues) => {
    const body = printJobsByIds.map(({ ID }) => {
      return { ID: ID, ...values, STATUS: 21 };
    });

    new Promise((resolve, reject) => {
      dispatch(
        updatePrintJob({
          body: body,
        })
      )
        .then(() => resolve(navigate(`/${lang}/${t("router.mijn-wachtrij")}`)))
        .catch((err) => reject(err));
    });
  };

  if (isLoading) return <Spinner />;
  return (
    <>
      <Box>
        <Title title={t("printManagement.confirmUpload.title")} />
        <AnimatedPage animationType={"FadeInUp"}>
          <Container
            maxW={"container.xl"}
            p={{ base: "22px 16px", md: "45px 32px" }}
          >
            <SimpleGrid
              columns={{ base: 1, lg: 2 }}
              spacing={{ base: "20px", md: "40px", lg: "80px" }}
            >
              <Box>
                <Heading
                  mb={{ base: "20px", md: "32px" }}
                  fontSize={"2xl"}
                  fontWeight={"normal"}
                >
                  {t("printManagement.confirmUpload.subTitle")}
                </Heading>

                <SimpleGrid
                  columns={{ base: 1, sm: 2, md: 3, lg: 2, xl: 3 }}
                  gap={22}
                >
                  {printJobsByIds.map((printJob, index) => (
                    <Box
                      borderWidth={"1px"}
                      borderColor={"custom.gray_300"}
                      p={"16px"}
                      key={index}
                    >
                      <VStack spacing={"22px"} alignItems={"flex-start"}>
                        <VStack
                          spacing={"12px"}
                          alignItems={"flex-start"}
                          w={"full"}
                        >
                          <Circle size={"40px"} bg={"custom.brand_primary"}>
                            <Icon
                              as={FiFile}
                              boxSize={"20px"}
                              color={"custom.brand_primaryLight"}
                            />
                          </Circle>
                          <Text wordBreak={"break-word"}>
                            {printJob?.DOCUMENT_NAME}
                          </Text>
                        </VStack>
                        ))
                      </VStack>
                    </Box>
                  ))}
                </SimpleGrid>
              </Box>
              <PrintPreferences onSubmit={handleSubmit} />
            </SimpleGrid>
          </Container>
        </AnimatedPage>
      </Box>
    </>
  );
};

export default BevestigUpload;
