import { createSlice } from "@reduxjs/toolkit";
import { getPatronInfo } from "@features/user/action";
import { userSateI } from "@features/user/types";

const initialState: userSateI = {
  isLoading: false,
  userInfo: null,
  error: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUser: (state, action) => action.payload,
  },
  extraReducers: (builder) => {
    builder.addCase(getPatronInfo.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getPatronInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userInfo = action.payload;
    });
    builder.addCase(getPatronInfo.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default userSlice.reducer;
