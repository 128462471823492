import React, { PropsWithChildren, useRef } from "react";
import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";

const TabPanelContent = ({ children }: PropsWithChildren<any>) => {
  const currentPanelRef = useRef(document.createElement("div"));

  const routesAnimationVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: { delay: 0, duration: 0.6 },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.4,
      },
    },
  };

  return (
    <Box
      as={motion.div}
      variants={routesAnimationVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      ref={currentPanelRef}
    >
      {children}
    </Box>
  );
};

export default TabPanelContent;
