import React, { ReactNode } from "react";
import { Link as RouteLink, NavLink } from "react-router-dom";
import { forwardRef, Link, LinkProps } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface LocalizedLink {
  subPath?: string;
  navLink?: boolean;
  state?: {};
  children: ReactNode;
}

const Index = forwardRef<LinkProps & LocalizedLink, "a">((props, ref) => {
  const { i18n } = useTranslation();

  const { subPath, navLink, state, children, ...restProps } = props;

  return navLink && navLink ? (
    <Link
      as={NavLink}
      to={`/${i18n.language}${subPath ? subPath : ""}`}
      state={state}
      ref={ref}
      {...restProps}
      _hover={{
        background: "custom.gray_100",
        textDecoration: "none",
      }}
    >
      {children}
    </Link>
  ) : (
    <Link
      as={RouteLink}
      to={`/${i18n.language}${subPath ? subPath : ""}`}
      state={state}
      ref={ref}
      {...restProps}
    >
      {children}
    </Link>
  );
});

export default Index;
