import React, {FC, useEffect, useState} from "react";
import {
    Badge,
    Box,
    Button,
    Flex,
    Heading,
    HStack,
    IconButton,
    Image,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import {FiX} from "react-icons/fi";
import {SingleDatepicker} from "@components/date-picker";
import {endOfToday, set} from "date-fns";
import TimelineSlider from "@components/timeline-slider";
import {makeReservation} from "@features/reservation/action";
import {useDispatch} from "react-redux";
import {AppDispatch, RootState} from "@src/store";
import {useAppSelector} from "@store/hooks";
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {useNavigate} from "react-router-dom";
import {formatDate} from "@helpers/helpers";
import moment from "moment";

interface ReservationObjI {
    data: ReservationDataI;
    selectedDate: Date;
    selectedStartTime: any;
    selectedEndTime: any;
    objectImages: any;
}

interface ReservationDataI {
    title: string;
    lokaal: string;
    status: ReservationStatus | string;
    types: ReservationTypes[];
    startTime: any;
    stopTime: any;
    ID: number;
    DISPLAY_NAME: string;
    ROOM_NAME: string;
}

type ReservationStatus = "success" | "unavailable" | "maybe";

interface ReservationTypes {
    title: string;
}

const Index: FC<ReservationObjI> = ({
                                        data: {
                                            ID,
                                            DISPLAY_NAME,
                                            ROOM_NAME,
                                            startTime,
                                            stopTime
                                        },
                                        selectedDate,
                                        selectedStartTime,
                                        selectedEndTime,
                                        objectImages
                                    }) => {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [setDate] = useState(new Date());
    const [error, setError] = useState<any>(false);
    const dispatch = useDispatch<AppDispatch>();
    const {t} = useTranslation();

    const now = new Date();
    const getTodayAtSpecificHour = (hour = 12) =>
        set(now, {hours: hour, minutes: 0, seconds: 0, milliseconds: 0});

    const x = getTodayAtSpecificHour(7);
    const y = endOfToday();

    const quarterHours = ["00", "15", "30", "45"];

    let times = [];

    for (let i = x.getHours(); i < 24; i++) {
        for (let j = 0; j < 4; j++) {
            times.push(("0" + i).slice(-2) + ":" + quarterHours[j]);
        }
    }

    const getDate = (hour: number, minutes: number) => {
        // @ts-ignore
        return set(new Date(moment(startTime)), {
            hours: hour,
            minutes: minutes,
            seconds: 0,
            milliseconds: 0
        });
    };

    const selectedStart = getDate(selectedStartTime.split(":")[0], selectedStartTime.split(":")[1]);
    const selectedEnd = getDate(selectedEndTime.split(":")[0], selectedEndTime.split(":")[1]);

    const [selectedInterval, setSelectedInterval] = useState([selectedStart, selectedEnd]);

    const lang = i18n.language;
    const navigate = useNavigate();


    const {reservationCreated} = useAppSelector(
        (state: RootState) => state.reservation
    );

    useEffect(() => {
        if (reservationCreated) {
            navigate(`/${lang}/${t("router.mijn-reservaties")}`);
        }
    }, [lang, navigate, reservationCreated, t])

    const confirm = async () => {
        try {
            await dispatch(makeReservation({
                startTime: formatDate(selectedDate) + ' ' + selectedStartTime,
                stopTime: formatDate(selectedDate) + ' ' + selectedEndTime,
                objectID: ID,
                specialModeID: 1
            }))
            navigate(`/${lang}${t("pageRoutes.mijn-reservaties")}`);
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Box>
            <Flex
                flexDir={{base: "column", sm: "row"}}
                borderColor={"custom.gray_300"}
                borderWidth={"1px"}
                maxW={"600px"}
                margin={"auto"}
            >
                {objectImages.filter((objectImage: any) => objectImage.DISPLAY_NAME === DISPLAY_NAME)[0]?.OBJECT_IMAGE &&
                    <Image
                        src={"data:image/jpg;base64," + objectImages.filter((objectImage: any) => objectImage.DISPLAY_NAME === DISPLAY_NAME)[0].OBJECT_IMAGE}
                        objectFit={{base: "cover"}}
                        w={{base: "100%", sm: "180px"}}
                        height={{base: "150px", sm: "auto"}}
                        borderBottomRightRadius={"60px"}
                    />
                }
                <Box w={"full"} padding={{base: "22px", sm: "32px"}}>
                    <Flex
                        flexDir={{base: "column", md: "row"}}
                        justifyContent={"space-between"}
                        alignItems={"flex-start"}
                        mb={"26px"}
                    >
                        <VStack spacing={"6px"} alignItems={"flex-start"} ml={0}>
                            <Heading fontSize={"lg"} fontWeight={"semibold"}>
                                {DISPLAY_NAME}
                            </Heading>
                            <Text>{ROOM_NAME}</Text>
                        </VStack>
                        <Badge
                            order={{base: -1, md: 0}}
                            mb={{base: "12px", md: "0"}}
                            colorScheme={
                                error === true
                                    ? "red"
                                    : "green"
                            }
                        >
                            {error ? "Niet beschikbaar" : "Beschikbaar"}
                        </Badge>
                    </Flex>

                    <HStack mb={"26px"}>
                        <TimelineSlider
                            error={error}
                            setError={setError}
                            ticksNumber={9}
                            timelineInterval={[x, y]}
                            selectedStartEnd={[selectedStart, selectedEnd]}
                            isSelectedInterval={false}
                            setSelectedInterval={setSelectedInterval}
                            selectedInterval={selectedInterval}
                            disabledIntervals={[
                                {
                                    start: getTodayAtSpecificHour(0),
                                    end: getTodayAtSpecificHour(0)
                                }
                            ]}
                        />
                    </HStack>

                    <Button variant={"outline"} onClick={onOpen}>
                        {t('newReservation.bookReservation.btn')}
                    </Button>
                </Box>
            </Flex>


            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay/>
                <ModalContent
                    maxW={"1000px"}
                    borderRadius={0}
                    m={{base: "16px", md: "32px"}}
                >
                    <IconButton
                        icon={<FiX/>}
                        aria-label={"Close modal"}
                        onClick={onClose}
                        position="absolute"
                        top={"22px"}
                        right={"32px"}
                        w={"44px"}
                        h={"44px"}
                        fontSize="20px"
                    />
                    <ModalBody padding={"0"}>
                        <Flex flexDir={{base: "column", md: "row"}}>
                            { objectImages.filter((objectImage: any) => objectImage.DISPLAY_NAME === DISPLAY_NAME)[0]?.OBJECT_IMAGE && <Image
                                src={"data:image/png;base64," + objectImages.filter((objectImage: any) => objectImage.DISPLAY_NAME === DISPLAY_NAME)[0].OBJECT_IMAGE}
                                objectFit={{base: "cover"}}
                                w={{base: "100%", md: "274px"}}
                                height={{base: "250px", md: "auto"}}
                                borderBottomRightRadius={"60px"}
                            />
                            }
                            <Flex
                                flexDir={"column"}
                                padding={{base: "50px 16px", md: "120px 50px 60px"}}
                                w={"full"}
                            >
                                <Heading mb={"32px"}>
                                    {DISPLAY_NAME} ({ROOM_NAME})
                                </Heading>
                                <VStack spacing={"22px"} mb={"50px"} alignItems={"flex-start"}>
                                    <SingleDatepicker
                                        name="date-input"
                                        date={selectedDate}
                                        onDateChange={setDate}
                                        disabled={true}
                                    />

                                    <VStack spacing={"12px"} w={"full"}>
                                        <TimelineSlider
                                            error={error}
                                            setError={setError}
                                            ticksNumber={9}
                                            timelineInterval={[x, y]}
                                            disabledIntervals={[{
                                                start: getTodayAtSpecificHour(0),
                                                end: getTodayAtSpecificHour(0)
                                            }]}
                                            selectedStartEnd={[selectedStart, selectedEnd]}
                                            isSelectedInterval={true}
                                            setSelectedInterval={setSelectedInterval}
                                            selectedInterval={selectedInterval}
                                            selectedDate={selectedDate}
                                            selectedStartTime={selectedStartTime}
                                            selectedEndTime={selectedEndTime}
                                        />
                                    </VStack>
                                </VStack>

                                <Button variant={"primary"} disabled={error} onClick={confirm}>
                                    {t('newReservation.confirm.btn')}
                                </Button>
                            </Flex>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default Index;
