import {
  Collapse,
  Flex,
  Icon,
  Link,
  Stack,
  Text,
  useDisclosure,
  forwardRef,
  FlexProps,
} from "@chakra-ui/react";
import { FiChevronDown } from "react-icons/fi";
import LocalizedLink from "@components/localized-link";
import { NavItemsI } from "@components/navbar/interfaces";

const IsolatedMobileNavItem = forwardRef<FlexProps & NavItemsI, "div">(
  (props, ref) => {
    const { isOpen, onToggle } = useDisclosure();

    const { children, title, ...restProps } = props;

    return (
      <Flex flexDir={"column"} {...restProps} ref={ref}>
        <Flex
          py={"0"}
          as={Link}
          justify={"space-between"}
          align={"center"}
          _hover={{
            textDecoration: "none",
          }}
          onClick={children && onToggle}
        >
          <Text>{title}</Text>
          {children && (
            <Icon
              as={FiChevronDown}
              transition={"all .25s ease-in-out"}
              transform={isOpen ? "rotate(180deg)" : ""}
              w={6}
              h={6}
            />
          )}
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          <Stack
            my={"12px"}
            pl={"12px"}
            borderLeft={1}
            borderStyle={"solid"}
            borderColor={"custom.gray_400"}
            align={"start"}
          >
            {children &&
              children.filter(({active}) => active).map(({ subTitle, path }, index) => (
                <LocalizedLink
                  subPath={path}
                  navLink={true}
                  display={"flex"}
                  w={"full"}
                  p={"12px"}
                  key={index}
                >
                  {subTitle}
                </LocalizedLink>
              ))}
          </Stack>
        </Collapse>
      </Flex>
    );
  }
);
export default IsolatedMobileNavItem;
