import React, { PropsWithChildren } from "react";
import { useRadio, Box, UseRadioProps } from "@chakra-ui/react";

interface RadioCardProps extends UseRadioProps {
  children: string | PropsWithChildren<{}>;
}

const RadioCard = (props: RadioCardProps) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...checkbox}
        borderWidth={"2px"}
        borderRadius="0"
        cursor="pointer"
        _checked={{
          bg: "custom.brand_primary",
          color: "white",
          borderColor: "custom.brand_primary",
        }}
        _focus={{
          borderColor: "custom.brand_primaryLight",
        }}
        px={"16px"}
        py={"9px"}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default RadioCard;
