import React, {useEffect, useState} from "react";
import Title from "@components/title";
import AnimatedPage from "@components/animated-page";
import AnimatedTabContent from "@components/tabs/animated-tab-content";
import {
    Box, Button,
    Container,
    Flex,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
    SimpleGrid,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text, useDisclosure,
    VStack,
} from "@chakra-ui/react";
import {FiCalendar, FiClock, FiX} from "react-icons/fi";
import {
    deleteMyReservation,
    getMyReservations,
    reservationSignin,
    reservationSignoff
} from "@features/reservation/action";
import {useDispatch} from "react-redux";
import {AppDispatch, RootState} from "@src/store";
import {useAppSelector} from "@store/hooks";
import {useTranslation} from "react-i18next";
import moment from "moment";
import useNow from "@hooks/useNow";
import {toast} from "react-toastify";
import i18n from "i18next";
import {
    getObjectImages
} from "@features/reservation/action";
import useTitle from "@hooks/useTitle";

const MijnReservaties = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {t} = useTranslation();
    const [render, setRender] = useState(false);
    const [currentResId, setCurrentResId] = useState(false);
    const [code, setCode] = useState();
    const now = useNow(60000);

    const {myReservations, objectImages} = useAppSelector(
        (state: RootState) => state.reservation);

    useTitle(t(("documentTitle.reservationManagement")))

    useEffect(() => {
        (async () => {
            await dispatch(getMyReservations())
            await dispatch(getObjectImages({siteID: 0}))
        })();

        return () => setRender(false)
    }, [dispatch, render, now])


    const TABS = [
        {
            title: t("myReservations.plannedReservations"),
        }
    ];

    return (
        <>
            <Title title={t("myReservations.title")}/>
            <AnimatedPage animationType={"FadeInUp"}>
                <Container
                    maxW={"container.xl"}
                    p={{base: "22px 16px", md: "45px 32px"}}
                >
                    <Tabs variant="primary" isLazy>
                        <Grid
                            templateColumns={"repeat(5, 1fr)"}
                            gap={{base: "22px", md: "100px"}}
                        >
                            <GridItem colSpan={{base: 5, md: 2}}>
                                <TabList as={Flex} flexDirection={"column"} gap={"12px"}>
                                    {TABS.map(({title}, index) => (
                                        <Tab key={index}>{title}</Tab>
                                    ))}
                                </TabList>
                            </GridItem>
                            <GridItem colSpan={{base: 5, md: 3}}>
                                <TabPanels>
                                    <TabPanel>
                                        <AnimatedTabContent>
                                            {myReservations.length ?
                                                <>
                                                    {
                                                        myReservations.map(({
                                                                                RES_ID,
                                                                                objectName,
                                                                                startTime,
                                                                                stopTime,
                                                                                IS_PC,
                                                                                LoggedOn
                                                                            }: any) => (
                                                            <Flex
                                                                flexDir={{base: "column", lg: "row"}}
                                                                borderColor={"custom.gray_300"}
                                                                borderWidth={"1px"}
                                                                marginBottom={'20px'}
                                                                key={RES_ID}
                                                            >
                                                                { objectImages.filter((objectImage: any) => objectImage.DISPLAY_NAME === objectName)[0]?.OBJECT_IMAGE &&
                                                                <Image
                                                                    src={"data:image/png;base64," + objectImages.filter((objectImage: any) => objectImage.DISPLAY_NAME === objectName)[0].OBJECT_IMAGE}
                                                                    objectFit={{base: "cover"}}
                                                                    w={{base: "100%", lg: "180px"}}
                                                                    height={{base: "150px", lg: "auto"}}
                                                                    borderRadius={{base: "5px", md: "0 0 60px 0"}}
                                                                    p={{base: "22px 22px 0px 22px", md: "0"}}
                                                                /> }

                                                                <Box w={"full"} padding={{base: "22px", sm: "32px"}}>
                                                                    <Flex
                                                                        flexDir={{base: "column"}}
                                                                        gap={"16px"}
                                                                        justifyContent={"space-between"}
                                                                        alignItems={"flex-start"}
                                                                        w={"full"}
                                                                    >
                                                                        <VStack
                                                                            spacing={"16px"}
                                                                            alignItems={"flex-start"}
                                                                            w={"full"}
                                                                        >
                                                                            {
                                                                                !LoggedOn &&
                                                                                <Flex justifyContent={"flex-end"} w={"full"}>
                                                                                    <Button leftIcon={<FiX/>}
                                                                                            variant={'deleteLink'}
                                                                                            onClick={() => {
                                                                                                onOpen();
                                                                                                setCurrentResId(RES_ID)
                                                                                            }
                                                                                            }>
                                                                                        {t("myReservations.deleteReservation.title")}
                                                                                    </Button>
                                                                                </Flex>
                                                                            }
                                                                            <VStack alignItems={"flex-start"}>
                                                                                <Heading
                                                                                    fontSize={"lg"}
                                                                                    fontWeight={"semibold"}
                                                                                >
                                                                                    {objectName}
                                                                                </Heading>
                                                                            </VStack>
                                                                            <VStack alignItems={"flex-start"}>
                                                                                <HStack>
                                                                                    <Icon as={FiCalendar}
                                                                                          color={"custom.gray_500"}/>
                                                                                    <Text color={"custom.gray_500"}>
                                                                                        {moment(startTime.replace(/-/g, "/")).format('DD/MM/YYYY')}
                                                                                    </Text>
                                                                                </HStack>
                                                                                <HStack>
                                                                                    <Icon as={FiClock}
                                                                                          color={"custom.gray_500"}/>
                                                                                    <Text color={"custom.gray_500"}>
                                                                                        {moment(startTime.replace(/-/g, "/")).format('HH:mm')} - {moment(stopTime.replace(/-/g, "/")).format('HH:mm')}
                                                                                    </Text>
                                                                                </HStack>
                                                                            </VStack>
                                                                            {
                                                                                !IS_PC && moment(startTime.replace(/-/g, "/")).format('YYYY/MM/DD HH:mm') <= moment().format('YYYY/MM/DD HH:mm') &&
                                                                                <SimpleGrid columns={{base: 1, md: 2}}
                                                                                            gap={"20px"}>
                                                                                    {
                                                                                        !LoggedOn &&
                                                                                        <>
                                                                                            <Input value={code}
                                                                                                   disabled={LoggedOn}
                                                                                                   variant={"filled"}
                                                                                                   placeholder={t('myReservations.code')}
                                                                                                   onChange={(e: any) => setCode(e.target.value)}/>
                                                                                            <Button
                                                                                                variant={"outline"}
                                                                                                disabled={LoggedOn}
                                                                                                onClick={() => {
                                                                                                    return new Promise((resolve, reject) => {
                                                                                                        dispatch(reservationSignin({ObjectName: code}))
                                                                                                            .then((res: any) => {
                                                                                                                    if (res?.payload.data.error) {
                                                                                                                        toast.error(res?.payload.data.error);
                                                                                                                    } else {
                                                                                                                        toast.success(i18n.t("reservationSignin.successMsg"));
                                                                                                                        setRender(true)
                                                                                                                    }
                                                                                                                }
                                                                                                            )
                                                                                                            .catch((err) => {
                                                                                                                reject(err)
                                                                                                            });
                                                                                                    })
                                                                                                }}
                                                                                            >
                                                                                                {!LoggedOn ? t('myReservations.signIn') : t('myReservations.signedIn')}
                                                                                            </Button>
                                                                                        </>
                                                                                    }
                                                                                    {
                                                                                        LoggedOn && <Button
                                                                                            variant={"outline"}
                                                                                            onClick={() => {
                                                                                                return new Promise((resolve, reject) => {
                                                                                                    dispatch(reservationSignoff({ObjectName: objectName}))
                                                                                                        .then((res: any) => {
                                                                                                                if (res?.payload.data.error) {
                                                                                                                    toast.error(res?.payload.data.error);
                                                                                                                } else {
                                                                                                                    toast.success(i18n.t("reservationSignoff.successMsg"));
                                                                                                                    setRender(true)
                                                                                                                }
                                                                                                            }
                                                                                                        )
                                                                                                        .catch((err) => {
                                                                                                            reject(err)
                                                                                                        });
                                                                                                })
                                                                                            }}
                                                                                        >
                                                                                            { t('myReservations.signOff') }
                                                                                        </Button>
                                                                                    }
                                                                                </SimpleGrid>
                                                                            }
                                                                        </VStack>
                                                                    </Flex>
                                                                </Box>
                                                            </Flex>
                                                        ))
                                                    }
                                                </>
                                                : <Text>{t("myReservations.plannedReservations.notFound")}</Text>}
                                        </AnimatedTabContent>
                                    </TabPanel>
                                </TabPanels>

                                {isOpen && (
                                    <Modal isOpen={isOpen} onClose={onClose} isCentered>
                                        <ModalOverlay/>
                                        <ModalContent>
                                            <ModalHeader>
                                                {t("myReservations.deleteReservation.modal.title")}
                                            </ModalHeader>
                                            <ModalCloseButton/>

                                            <ModalBody>
                                                {t("myReservations.deleteReservation.modal.description")}
                                            </ModalBody>

                                            <ModalFooter>
                                                <Button mr={3} onClick={onClose}>
                                                    {t("myReservations.deleteReservation.modal.cancelBtn")}
                                                </Button>
                                                <Button
                                                    variant="primary"
                                                    onClick={async () => {
                                                        await dispatch(deleteMyReservation({reservationId: currentResId}));
                                                        setRender(true);
                                                        onClose();
                                                    }}
                                                >
                                                    {t("myReservations.deleteReservation.modal.confirmBtn")}
                                                </Button>
                                            </ModalFooter>
                                        </ModalContent>
                                    </Modal>
                                )}
                            </GridItem>
                        </Grid>
                    </Tabs>
                </Container>
            </AnimatedPage>
        </>
    );
};

export default MijnReservaties;
