import {createAsyncThunk} from "@reduxjs/toolkit";
import {http} from "@utils/http";
import i18n from "i18next";
import {toast} from "react-toastify";

export const getFloormap = createAsyncThunk(
    "site/getFloorMap",
    async ({siteID}: any, {rejectWithValue}) => {
        try {
            const {data} = await http.get("/site/getFloorMap/" + siteID);
            return data;
        } catch (e) {
            rejectWithValue({e});
        }
    }
);

export const getObjectImages = createAsyncThunk(
    "site/getObjectImages",
    async ({siteID}: any, {rejectWithValue}) => {
        try {
            const {data} = await http.get("/site/getObjectImages/" + siteID);
            return data;
        } catch (e) {
            rejectWithValue({e});
        }
    }
);

export const getSites = createAsyncThunk(
    "site/getSites",
    async (_, {rejectWithValue}) => {
        try {
            let {data} = await http.get("/site/getSites/");
            data = data.map(({ID, DISPLAY_NAME}: any) => ({
                'ID': ID,
                'label': DISPLAY_NAME
            }));
            return data;
        } catch (e) {
            rejectWithValue({e});
        }
    }
);

export const getSitesWorkplace = createAsyncThunk(
    "site/getSitesWorkplace",
    async (_, {rejectWithValue}) => {
        try {
            let {data} = await http.get("/site/getSitesWorkplace/");
            data = data.map(({ID, DISPLAY_NAME}: any) => ({
                'ID': ID,
                'label': DISPLAY_NAME
            }));
            return data;
        } catch (e) {
            rejectWithValue({e});
        }
    }
);

export const getSpecialModes = createAsyncThunk(
    "site/getSpecialModes",
    async (_, {rejectWithValue}) => {
        try {
            let {data} = await http.get("/site/getSpecialModes/");
            data = data.map(({ID, TEXT}: any) => ({
                'ID': ID,
                'label': TEXT
            }));
            return data;
        } catch (e) {
            rejectWithValue({e});
        }
    }
);

export const getObjectTypes = createAsyncThunk(
    "site/getObjectTypes",
    async (_, {rejectWithValue}) => {
        try {
            const lang = i18n.language === 'en' ? 1 : i18n.language === 'nl' ? 2 : i18n.language === 'fr' ? 3 : i18n.language === 'de' ? 4 : null;

            let {data} = await http.get("/site/getObjectTypes/" + lang);

            data = data.map(({TEXT, ID}: any) => ({
                'ID': ID,
                'label': TEXT
            }));

            return data;
        } catch (e) {
            rejectWithValue({e});
        }
    }
);

export const getObjectsFiltered = createAsyncThunk(
    "site/getObjectsFiltered",
    async ({siteID, featureID, specialModeID, objectTypeID}: any, {rejectWithValue}) => {
        try {
            const {data} = await http.get("/site/getObjectsFiltered/" + siteID, {
                params: {
                    featureID: featureID,
                    specialModeID: specialModeID,
                    objectTypeID: objectTypeID
                }
            });
            return data;
        } catch (e) {
            rejectWithValue({e});
        }
    }
);


export const getReservations = createAsyncThunk(
    "site/getReservation/{id}",
    async ({id, date}: any, {rejectWithValue}) => {
        try {
            const {data} = await http.get("/site/getReservations/" + id, {
                params: {
                    date: date
                }
            });
            return data;
        } catch (e) {
            rejectWithValue({e});
        }
    }
);

export const getReservationPossibilities = createAsyncThunk(
    "customerPortal/ReservationPossibilities",
    async ({siteID, resStart, resStop}: any, {rejectWithValue}) => {
        try {
            let {data} = await http.get("/customerPortal/ReservationPossibilities/", {
                params: {
                    siteID: siteID,
                    resStart: resStart,
                    resStop: resStop
                }
            });

            data.resultSet = data.resultSet.map(({OBJECT_NAME, OWNER_NAME, OBJECT_ID}: any) => ({
                'ID': OBJECT_ID,
                'DISPLAY_NAME': OBJECT_NAME,
                'ROOM_NAME': OWNER_NAME
            }));

            if (data.error) {
                toast.error(data.error);
            }

            return data.resultSet;
        } catch (e) {
            rejectWithValue({e});
        }
    }
);

export const clearReservations = createAsyncThunk(
    "clearReservations",
     () => {
        return;
    }
);

export const clearFilteredReservations = createAsyncThunk(
    "clearFilteredReservations",
    () => {
        return;
    }
);

export const makeReservation = createAsyncThunk(
    "customerportal/Reservation",
    async ({startTime, stopTime, objectID, specialModeID}: any, {rejectWithValue}) => {
        try {
            return await http.post("/customerportal/Reservation/", {
                startTime,
                stopTime,
                objectID,
                specialModeID
            });
        } catch (e) {
            rejectWithValue({e});
        }
    }
);

export const getOpeningTimes = createAsyncThunk(
    "site/getOpeningTimes/{id}",
    async ({siteID}: any, {rejectWithValue}) => {
        try {
            const {data} = await http.get("/site/getOpeningTimes/" + siteID);
            return data;
        } catch (e) {
            rejectWithValue({e});
        }
    }
);

export const getFeatures = createAsyncThunk(
    "site/getFeatures/",
    async (_, {rejectWithValue}) => {
        try {
            let {data} = await http.get("/site/getFeatures/");
            data = data.map(({ID, FEATURE_NAME}: any) => ({
                'ID': ID,
                'label': FEATURE_NAME
            }));
            return data;
        } catch (e) {
            rejectWithValue({e});
        }
    }
);

export const getMyReservations = createAsyncThunk(
    "customerportal/Reservations/",
    async (_, {rejectWithValue}) => {
        try {
            const {data} = await http.get("/customerportal/Reservations/");
            return data;
        } catch (e) {
            rejectWithValue({e});
        }
    }
);

export const getReservationSettings = createAsyncThunk(
    "customerportal/getReservationSettings/",
    async ({siteID}: any, {rejectWithValue}) => {
        try {
            const {data} = await http.get("/customerportal/getReservationSettings/" + siteID);
            return data;
        } catch (e) {
            rejectWithValue({e});
        }
    }
);

export const deleteMyReservation = createAsyncThunk(
    "customerportal/Reservations/{reservationId}",
    async ({reservationId}: any, {rejectWithValue}) => {
        try {
            const {data} = await http.delete("/customerportal/Reservations/" + reservationId);
            toast.success(i18n.t("myReservations.deleteReservation.toast.successMsg"));
            return data;
        } catch (e) {
            rejectWithValue({e});
            toast.success(i18n.t("myReservations.deleteReservation.toast.errorMsg"));
        }
    }
);

export const reservationSignin = createAsyncThunk(
    "customerportal/ReservationSignin",
    async (t: any, {rejectWithValue}) => {
        try {
            return await http.post("/customerportal/ReservationSignin/", {...t});
        } catch (e) {
            rejectWithValue({e});
        }
    }
);

export const reservationSignoff = createAsyncThunk(
    "customerportal/ReservationSignoff",
    async (t: any, {rejectWithValue}) => {
        try {
            return await http.post("/customerportal/ReservationSignoff/", {...t});
        } catch (e) {
            rejectWithValue({e});
        }
    }
);

export const setLang = createAsyncThunk(
    "customerportal/Language",
    async ({userLanguage}: any, {rejectWithValue}) => {
        try {
            return await http.put("/customerportal/Language/", {
                userLanguage
            });
        } catch (e) {
            rejectWithValue({e});
        }
    }
);