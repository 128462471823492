import * as React from "react";
import {
    Flex,
    Box, Heading, Text, VStack, Button, HStack, Image,
} from "@chakra-ui/react";
import {useAppSelector} from "@store/hooks";
import {RootState} from "@src/store";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import i18n from "i18next";
import LanguageDropdown from "@components/language-dropdown";
import LocalizedLink from "@components/localized-link";
import logo from "@assets/images/logo.svg";
import useTitle from "@hooks/useTitle";

const ChooseCategory = () => {
    const {userInfo} = useAppSelector((state: RootState) => state.user);
    const {userSettings} = useAppSelector((state: RootState) => state.auth);
    const {t} = useTranslation();
    const lang = i18n.language;
    const navigate = useNavigate();

    const filteredArr = Object.keys(userSettings).filter(key => userSettings[key]);

    useTitle(t(("documentTitle.chooseCategory")))

    useEffect(() => {
        if (filteredArr.length === 1 && userSettings?.BYODAvailable) {
            navigate(`/${lang}/${t("router.nieuwe-prints")}`);
        } else if (filteredArr.length === 1 && userSettings?.reservePCAvailable) {
            navigate(`/${lang}/${t("router.create-pc-reservation")}`);
        } else if (filteredArr.length === 1 && userSettings?.reservePlaceAvailable) {
            navigate(`/${lang}/${t("router.create-workplace-reservation")}`);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredArr])

    return (
        <Flex
            height={"100vh"}
            position={"relative"}
            overflow={"hidden"}
            align={"center"}
            justifyContent={"center"}
            bg={"custom.brand_primaryDark"}
        >
            <Box
                pos={"absolute"}
                top={"-30vw"}
                left={"-20vw"}
                w={"50vw"}
                h={"50vw"}
                transform={"rotate(45deg)"}
                bg={"custom.brand_loginBlock"}
                borderRadius={"10vw"}
            />

            <Flex
                pos={"relative"}
                zIndex={"1"}
                flexDir={"column"}
                flexWrap={"wrap"}
                mx={{base: "16px"}}
                p={{base: "16px", sm: "32px"}}
                bg={"custom.gray_50"}
            >
                <VStack spacing={"40px"}>
                    <Box w={"auto"} marginLeft={'auto'}>
                        <LanguageDropdown subPath={t("pageRoutes.choose-category")} />
                    </Box>

                    <LocalizedLink subPath={t("pageRoutes.choose-category")} display={"block"} w={"100%"} maxW={{base: "220px", md: "260px"}}>
                        <Image
                            src={logo}
                            alt={"logo"}
                            w={"full"}
                        />
                    </LocalizedLink>

                    <VStack spacing={"22px"} alignItems={"center"} p={{base: "0", sm: "0 32px"}}>
                        <Heading fontSize="26px" textAlign={"center"}>{t("choose-category.greeting", {name: `${userInfo?.firstName} ${userInfo?.lastName}`})}</Heading>
                        <Text textAlign={"center"}>{t("choose-category.help-text")}</Text>
                    </VStack>
                    <HStack flexDir={{base: "column", lg: "row"}} p={{base: "0", sm: "0 32px"}}>
                        {
                            userSettings?.BYODAvailable && <>
                                <Button whiteSpace={{base: "normal", sm: 'nowrap'}} onClick={() => {navigate(`/${lang}/${t("router.nieuwe-prints")}`)}}>{t("choose-category.BYODAvailable")}</Button>
                            </>
                        }
                        {
                            userSettings?.reservePCAvailable && <>
                                <Button whiteSpace={{base: "normal", sm: 'nowrap'}} onClick={() => {navigate(`/${lang}/${t("router.create-pc-reservation")}`)}}>{t("choose-category.reservePCAvailable")}</Button>
                            </>
                        }
                        {
                            userSettings?.reservePlaceAvailable && <>
                                <Button whiteSpace={{base: "normal", sm: 'nowrap'}} onClick={() => {navigate(`/${lang}/${t("router.create-workplace-reservation")}`)}}>{t("choose-category.reservePlaceAvailable")}</Button>
                            </>
                        }
                        {
                            (userSettings?.reservePCAvailable || userSettings?.reservePlaceAvailable) && <>
                                <Button whiteSpace={{base: "normal", sm: 'nowrap'}} onClick={() => {navigate(`/${lang}/${t("router.mijn-reservaties")}`)}}>{t("choose-category.myReservations")}</Button>
                            </>
                        }
                    </HStack>
                </VStack>
            </Flex>

            <Box
                pos={"absolute"}
                bottom={"-30vw"}
                right={"-20vw"}
                w={"50vw"}
                h={"50vw"}
                transform={"rotate(45deg)"}
                bg={"custom.brand_loginBlock"}
                borderRadius={"10vw"}
            />
        </Flex>
    );
};

export default ChooseCategory;
