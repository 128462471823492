import { createSlice } from "@reduxjs/toolkit";
import { handleUploadFile, revertAll } from "@features/upload/action";
import { uploadStateI } from "@features/upload/type";

const initialState: uploadStateI = {
  loading: false,
  upload: [],
  error: null,
};

export const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleUploadFile.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(handleUploadFile.fulfilled, (state, action) => {
      state.loading = false;
      state.upload?.push(action.payload);
    });
    builder.addCase(handleUploadFile.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(revertAll, () => initialState);
  },
});

export default uploadSlice.reducer;
