import {Alert} from "./alert";
import Input from "./input";
import NumberInput from "./number-input";
import Button from "./button";
import Avatar from "./avatar";
import Progress from "./progress";
import Select from "./select";
import Menu from "./menu";
import Popover from "./popover";
import Tabs from "./tabs";
import Checkbox from "./checkbox";

const components = {
  Alert,
  Input,
  NumberInput,
  Button,
  Avatar,
  Progress,
  Select,
  Menu,
  Popover,
  Tabs,
  Checkbox
};

export default components;
