import { AlertProps, createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { alertAnatomy } from '@chakra-ui/anatomy'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(alertAnatomy.keys)

const baseStyle = definePartsStyle((props: AlertProps) => {
  const { status } = props;

  const infoBase = status === "info" && {
    container: {
      background: 'custom.gray_100',
      color: 'custom.gray_800',
    },
    icon: {
      color: 'custom.brand_primary'
    }
  };

  return {
    ...infoBase
  };
})

export const Alert = defineMultiStyleConfig({ baseStyle })