import React from "react";
import { forwardRef, Button, ButtonProps } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Index = forwardRef<ButtonProps, "button">((props, ref) => {
  const navigate = useNavigate();
  const { title, ...restProps } = props;

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Button variant={"primary"} onClick={goBack} ref={ref} {...restProps}>
      {title}
    </Button>
  );
});

export default Index;
