import React from "react";
import { Box, Container, Input } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const Index = ({ filter, setFilter }: any) => {
  const { t } = useTranslation();

  return (
    <>
      <Box bg={"custom.gray_100"} mb={"45px"}>
        <Container maxW={"container.xl"} p={"32px"}>
          <Input
            placeholder={t("searchBox.text")}
            variant={"filled"}
            value={filter || ""}
            onChange={(e) => setFilter(e.target.value)}
          />
        </Container>
      </Box>
    </>
  );
};

export default Index;
