import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import {
  ChakraProvider,
  extendTheme,
  withDefaultVariant,
} from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import "@fontsource/poppins";
import Router from "@utils/applicationRoutes";

import { cssTransition, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { getWebsiteColors } from "@features/colors/action";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "@src/store";
import { useAppSelector } from "@store/hooks";
import foundations from "./theme/foundations";
import components from "./theme/components";
import styles from "./theme/styles";

export const App = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { websiteColors, loading } = useAppSelector(
    (state: RootState) => state.websiteColors
  );
  const customTransition = cssTransition({
    enter: "slide-top",
    exit: "fade-out",
  });

  useEffect(() => {
    (async () => {
      await dispatch(getWebsiteColors());
    })();
  }, [dispatch]);

  const groupBy = (key: any) => (websiteColors: any) =>
    websiteColors.reduce((objectsByKeyValue: any, obj: any) => {
      const value = obj[key];

      objectsByKeyValue[value] = obj.value;

      return objectsByKeyValue;
    }, {});

  const groupByGroupName = groupBy("valueName");

  const colors = {
    custom: {
      error: {
        timelineSlider: "rgba(245, 101, 101, 0.8)",
      },
      success: {
        timelineSlider: "rgba(72, 187, 120, 0.8)",
      },
      ...groupByGroupName(websiteColors),
    },
  };

  const overrides = {
    colors,
    ...foundations,
    components,
    styles,
  };

  const theme = extendTheme(
    overrides,
    withDefaultVariant({
      variant: "brand",
      components: ["Avatar", "Progress"],
    })
  );

  if (loading) return null;

  return (
    <ChakraProvider theme={theme}>
      <ToastContainer
        position={"top-right"}
        transition={customTransition}
        newestOnTop={true}
      />
      <AnimatePresence exitBeforeEnter>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </AnimatePresence>
    </ChakraProvider>
  );
};
