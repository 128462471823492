import { createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "@utils/http";

export const getPatronInfo = createAsyncThunk(
  "byod/getPatronInfo",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await http.get("/byod/getPatronInfo/");
      return data;
    } catch (e) {
      rejectWithValue({ e });
    }
  }
);
