import { PropsWithChildren, useEffect, useState } from "react";
import * as React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@src/store";
import { getPatronInfo } from "@features/user/action";
import { unwrapResult } from "@reduxjs/toolkit";
import Spinner from "@components/spinner";
import { Box } from "@chakra-ui/react";
import {setLang} from "@features/reservation/action";
import i18n from "i18next";

type Props = PropsWithChildren<{}>;

const ChooseCategoryLayout = ({ children }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [render, setRender] = useState(false);
  const lang = i18n.language;

  useEffect(() => {
    (async () => {
      unwrapResult(await dispatch(getPatronInfo()));
      await dispatch(setLang({userLanguage: lang.toUpperCase()}))
      setRender(true);
    })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!render) return <Spinner />;
  return (
    <>
      <Box as={"main"}>
        {children}
      </Box>
    </>
  );
};

export default ChooseCategoryLayout;
