import React, {useCallback, useEffect, useState} from "react";
import {
    GridItem,
    Input,
    InputGroup,
    InputLeftElement,
    List,
    ListItem,
    Popover,
    PopoverContent,
    PopoverTrigger,
    SimpleGrid,
} from "@chakra-ui/react";
import {FiClock} from "react-icons/fi";

interface timePickerI {
    inputPlaceholder: string;
    startTimeArray: string[];
    stopTimeArray: any;
    setSelectedStartTime: any;
    selectedStartTime: any;
    setSelectedEndTime: any;
    selectedEndTime: any;
    disabled?: boolean;
    maximumReservationTime: number;
    reservationBlockSize: number;
}

const Index = ({
                   inputPlaceholder,
                   startTimeArray,
                   stopTimeArray,
                   setSelectedStartTime,
                   selectedStartTime,
                   setSelectedEndTime,
                   selectedEndTime,
                   disabled,
                   reservationBlockSize,
                   maximumReservationTime
               }: timePickerI) => {
    const [popoverOpen, setPopoverOpen] = useState(false);

    const openPopover = useCallback(() => {
        setPopoverOpen(true);
    }, [setPopoverOpen]);

    const closePopover = useCallback(() => {
        setPopoverOpen(false);
    }, [setPopoverOpen]);

    const selectStartTime = (label: string) => {
        setSelectedStartTime(label);
        if (selectedStartTime && selectedEndTime) {
            closePopover();
        }
    };

    const selectEndTime = (label: string) => {
        setSelectedEndTime(label);
        if (selectedStartTime && selectedEndTime) {
            closePopover();
        }
    };

    useEffect(() => {
        if (
            (selectedStartTime &&
                selectedEndTime &&
                selectedStartTime >= selectedEndTime) ||
            (selectedStartTime &&
                !selectedEndTime &&
                selectedStartTime >= selectedEndTime)
        ) {
            setSelectedEndTime("");
            openPopover();
        } else {
            closePopover();
        }
    }, [
        closePopover,
        openPopover,
        selectedEndTime,
        selectedStartTime,
        setSelectedEndTime,
    ]);

    // @ts-ignore
    return (
        <Popover
            isOpen={popoverOpen}
            onClose={() => setPopoverOpen(false)}
            matchWidth={true}
        >
            <PopoverTrigger>
                <InputGroup>
                    <InputLeftElement
                        pointerEvents="none"
                        children={<FiClock size={"20px"} opacity={disabled ? '0.4' : "1"}/>}
                    />
                    <Input
                        placeholder={inputPlaceholder}
                        variant={"filled"}
                        value={
                            selectedStartTime &&
                            selectedEndTime &&
                            `${selectedStartTime}-${selectedEndTime}`
                        }
                        onChange={(event) => event.target.value}
                        onClick={() => setPopoverOpen(!popoverOpen)}
                        disabled={disabled}
                    />
                </InputGroup>
            </PopoverTrigger>
            <PopoverContent width={"inherit"}>
                <SimpleGrid columns={2} w={"inherit"}>
                    <GridItem>
                        <List maxH={"240px"} overflowY={"auto"}>
                            {startTimeArray &&
                                startTimeArray
                                    .map((item: any, index: number) => {
                                        return (
                                            <ListItem
                                                key={index}
                                                onClick={() => {
                                                    selectStartTime(item);
                                                }}
                                                value={
                                                    selectedStartTime &&
                                                    selectedEndTime &&
                                                    `${selectedStartTime}-${selectedEndTime}`
                                                }
                                                p={"8px"}
                                                background={
                                                    item === selectedStartTime ? "custom.gray_200" : "transparent"
                                                }
                                                cursor={"pointer"}
                                            >
                                                {item}
                                            </ListItem>
                                        );
                                    })}
                        </List>
                    </GridItem>
                    <GridItem>
                        <List maxH={"240px"} overflowY={"auto"}>
                            {selectedStartTime &&

                                stopTimeArray.filter((el: any) => (
                                        el !== selectedStartTime &&
                                        el > selectedStartTime)
                                ).slice(0, maximumReservationTime / reservationBlockSize)

                                    .map((item: any, index: number) => {
                                        return (
                                            <ListItem
                                                key={index}
                                                onClick={() => {
                                                    selectEndTime(item);
                                                }}
                                                p={"8px"}
                                                background={
                                                    item === selectedEndTime ? "custom.gray_200" : "transparent"
                                                }
                                                cursor={"pointer"}
                                            >
                                                {item}
                                            </ListItem>
                                        );
                                    })}
                        </List>
                    </GridItem>
                </SimpleGrid>
            </PopoverContent>
        </Popover>
    );
};

export default Index;
