const input = {
  // Styles for the base style
  baseStyle: {},
  // Styles for the size variations
  sizes: {
    md: {
      field: {
        borderRadius: 0,
        height: "44px",
      },
    },
  },
  // Styles for the visual style variations
  variants: {
    filled: {
      field: {
        borderColor: "custom.gray_300",
        background: "white",
        _hover: {
          background: "white",
        },
        _focus: {
          borderColor: "custom.gray_500",
          background: "white",
          boxShadow: "none",
        },
      },
    },
  },
  // The default `size` or `variant` values
  defaultProps: {},
};

export default input;
