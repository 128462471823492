import React from "react";
import { usePagination, DOTS } from "@hooks/usePagination";
import { Button, HStack, List, ListItem } from "@chakra-ui/react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { useTranslation } from "react-i18next";

interface PaginationProps {
  onPageChange: (currentPage: any) => void;
  totalCount: number;
  siblingCount: number;
  currentPage: number;
  pageSize: number;
  className: string;
}

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}: PaginationProps) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  const { t } = useTranslation();

  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange && paginationRange[paginationRange.length - 1];
  return (
    <List display={"flex"}>
      <HStack spacing={"22px"}>
        <ListItem>
          <Button
            variant={"link"}
            display={"flex"}
            leftIcon={<FiArrowLeft />}
            onClick={onPrevious}
            isDisabled={currentPage === 1}
          >
            {t("pagination.previous.text")}
          </Button>
        </ListItem>
        <HStack spacing={"6px"}>
          {paginationRange &&
            paginationRange.map((pageNumber, index) => {
              if (pageNumber === DOTS) {
                return <ListItem key={index}>&#8230;</ListItem>;
              }
              return (
                <ListItem key={index}>
                  <Button
                    variant={"primaryPageButtons"}
                    onClick={() => onPageChange(pageNumber)}
                    isActive={pageNumber === currentPage}
                  >
                    {pageNumber}
                  </Button>
                </ListItem>
              );
            })}
        </HStack>
        <ListItem>
          <Button
            variant={"link"}
            display={"flex"}
            rightIcon={<FiArrowRight />}
            onClick={onNext}
            isDisabled={currentPage === lastPage}
          >
            {t("pagination.next.text")}
          </Button>
        </ListItem>
      </HStack>
    </List>
  );
};

export default Pagination;
