import React from "react";
import {
  Container,
  Flex,
  GridItem,
  Heading,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import RobotError from "../assets/images/robot-error.svg";
import GoBackButton from "../components/go-back-button";
import { useTranslation } from "react-i18next";
import useTitle from "@hooks/useTitle";

const PageNotFound = () => {
  const { t } = useTranslation();

  useTitle(t(("documentTitle.pageNotFound")))

  return (
    <>
      <Flex
        h={"100vh"}
        alignItems={"center"}
        justifyContent={"center"}
        bg={"custom.gray_25"}
      >
        <Container maxW={"container.lg"}>
          <SimpleGrid columns={{ base: 1, lg: 2 }} gap={{ base: 0, lg: 40 }}>
            <GridItem
              maxW={{ base: "275px", md: "570px", lg: "auto" }}
              margin={{ base: "auto" }}
            >
              <Flex
                flexDir={"column"}
                align={"flex-start"}
                justifyContent={"center"}
                textAlign={{ base: "center", lg: "left" }}
              >
                <VStack
                  spacing={"8px"}
                  align={{ base: "center", lg: "flex-start" }}
                  mb={"32px"}
                  w={"full"}
                >
                  <Heading>{t("pageNotFound.title")}</Heading>
                </VStack>

                <Text mb={"45px"}>{t("pageNotFound.body")}</Text>

                <GoBackButton
                  title={"Ga terug"}
                  margin={{ base: "auto", lg: "0" }}
                />
              </Flex>
            </GridItem>
            <GridItem
              order={{ base: -1, lg: 0 }}
              marginBottom={{ base: "45px", lg: "0" }}
            >
              <Flex justifyContent={{ base: "center", lg: "flex-end" }}>
                <Image
                  src={RobotError}
                  alt={"404 error"}
                  width={{ base: "320px", md: "350px", lg: "auto" }}
                />
              </Flex>
            </GridItem>
          </SimpleGrid>
        </Container>
      </Flex>
    </>
  );
};

export default PageNotFound;
