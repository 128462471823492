import * as React from "react";
import { Navigate, useRoutes, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import "@utils/i18n";
import { useAppSelector } from "@store/hooks";
import { RootState } from "@src/store";
import DashboardLayout from "@layouts/dashboard";
import ChooseCategoyLayout from "@layouts/choose-category";
import Login from "@pages/auth/Login";
import { NieuwePrints } from "@pages/dashboard/NieuwePrints";
import NoMatch from "@pages/PageNotFound";
import BevestigUpload from "@pages/dashboard/BevestigUpload";
import MijnWachtrij from "@pages/dashboard/MijnWachtrij";
import RecentePrints from "@pages/dashboard/RecentePrints";
import BeheerPrints from "@pages/dashboard/BeheerPrint";
import MijnReservaties from "@pages/dashboard/MijnReservaties";
import CreatePCReservation from "@pages/dashboard/CreatePCReservation";
import AnimatedPage from "@components/animated-page";
import CreateWorkPlaceReservation from "@pages/dashboard/CreateWorkPlaceReservation";
import LoginWorkplace from "@pages/auth/LoginWorkplace";
import ChooseCategory from "@pages/dashboard/ChooseCategory";

const Router = () => {
  const { t } = useTranslation();
  const { userToken } = useAppSelector((state: RootState) => state.auth);
  const lang = i18n.language;

  const PrivateOutlet = () => {
    return userToken ? <Outlet /> : <Navigate to={`/${lang}/login`} />;
  };

  const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    return userToken ? children : <Navigate to={`/${lang}/login`} />;
  };

  const routes = [
    {
      path: "/",
      element: <Navigate to={`/${lang}`} />,
    },
    {
      path: `/${lang}/login`,
      element: <Login />,
    },
    {
      path: `/${lang}/${t("router.signin-workplace")}/:objectName`,
      element: <LoginWorkplace />,
    },
    {
      path: `/${lang}`,
      element: <PrivateOutlet />,
      children: [
        {
          index: true,
          element: <Navigate to={`/${lang}/${t("router.choose-category")}`} />,
        },
        {
          path: `${t("router.nieuwe-prints")}`,
          element: (
            <>
              <DashboardLayout>
                <PrivateOutlet />
              </DashboardLayout>
            </>
          ),
          children: [
            {
              index: true,
              element: (
                <PrivateRoute>
                  <NieuwePrints />
                </PrivateRoute>
              ),
            }
          ],
        },
        {
          path: `${t("router.mijn-wachtrij")}`,
          element: (
            <>
              <DashboardLayout>
                <PrivateOutlet />
              </DashboardLayout>
            </>
          ),
          children: [
            {
              index: true,
              element: (
                <PrivateRoute>
                  <MijnWachtrij />
                </PrivateRoute>
              ),
            },
          ],
        },
        {
          path: `${t("router.recente-prints")}`,
          element: (
            <>
              <DashboardLayout>
                <PrivateOutlet />
              </DashboardLayout>
            </>
          ),
          children: [
            {
              index: true,
              element: (
                <PrivateRoute>
                  <RecentePrints />
                </PrivateRoute>
              ),
            },
          ],
        },
        {
          path: `${t("router.bevestig-upload")}`,
          element: (
              <>
                <DashboardLayout>
                  <PrivateOutlet />
                </DashboardLayout>
              </>
          ),
          children: [
            {
              index: true,
              element: (
                  <PrivateRoute>
                    <BevestigUpload />
                  </PrivateRoute>
              ),
            },
          ],
        },
        {
          path: `${t("router.beheer-print")}`,
          element: (
              <>
                <DashboardLayout>
                  <PrivateOutlet />
                </DashboardLayout>
              </>
          ),
          children: [
            {
              index: true,
              element: (
                  <PrivateRoute>
                    <BeheerPrints />
                  </PrivateRoute>
              ),
            },
          ],
        },
        {
          path: `${t("router.create-pc-reservation")}`,
          element: (
            <>
              <DashboardLayout>
                <PrivateOutlet />
              </DashboardLayout>
            </>
          ),
          children: [
            {
              index: true,
              element: (
                <PrivateRoute>
                  <CreatePCReservation />
                </PrivateRoute>
              ),
            },
          ],
        },
        {
          path: `${t("router.create-workplace-reservation")}`,
          element: (
              <>
                <DashboardLayout>
                  <PrivateOutlet />
                </DashboardLayout>
              </>
          ),
          children: [
            {
              index: true,
              element: (
                  <PrivateRoute>
                    <CreateWorkPlaceReservation />
                  </PrivateRoute>
              ),
            },
          ],
        },
        {
          path: `${t("router.mijn-reservaties")}`,
          element: (
            <>
              <DashboardLayout>
                <PrivateOutlet />
              </DashboardLayout>
            </>
          ),
          children: [
            {
              index: true,
              element: (
                <PrivateRoute>
                  <MijnReservaties />
                </PrivateRoute>
              ),
            },
          ],
        },
        {
          path: `${t("router.choose-category")}`,
          element: (
              <>
                <ChooseCategoyLayout>
                  <PrivateOutlet />
                </ChooseCategoyLayout>
              </>
          ),
          children: [
            {
              index: true,
              element: (
                  <PrivateRoute>
                    <ChooseCategory />
                  </PrivateRoute>
              ),
            },
          ],
        },
      ],
    },
    {
      path: "*",
      element: (
        <AnimatedPage animationType={"FadeIn"}>
          <NoMatch />
        </AnimatedPage>
      ),
    },
  ];

  return useRoutes(routes);
};

export default Router;
