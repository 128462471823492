import { PropsWithChildren, useEffect, useState } from "react";
import Navbar from "@components/navbar/navbar";
import * as React from "react";
import { useDispatch } from "react-redux";
import {AppDispatch} from "@src/store";
import { getPatronInfo } from "@features/user/action";
import Spinner from "@components/spinner";
import { Box, Container } from "@chakra-ui/react";
import Footer from "@components/footer";
import {setLang} from "@features/reservation/action";
import i18n from "i18next";

type Props = PropsWithChildren<{}>;

const Layout = ({ children }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [render, setRender] = useState(false);
 const lang = i18n.language;
  useEffect(() => {
    (async () => {
      await dispatch(getPatronInfo());
      await dispatch(setLang({userLanguage: lang.toUpperCase()}))
      setRender(true);
    })();


      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!render) return <Spinner />;
  return (
    <>
      <Navbar />

      <Box as={"main"} pb={"68px"}>
        {children}
      </Box>

      <Box
          pos={"absolute"}
          bottom={0}
          w={"full"}
          p={"22px"}
          background={"custom.brand_primary"}
          color={"white"}
      >
        <Container
            maxW={"container.xl"}
            p={"0 32px"}
        >
          <Footer />
        </Container>
      </Box>
    </>
  );
};

export default Layout;
