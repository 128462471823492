const styles = {
  global: ({ theme }: any) => ({
    "html, body, #root": {
      color: "custom.gray_700",
      fontFamily: '"Poppins", sans-serif',
      height: "100%",
      "--toastify-icon-color-success": "var(--chakra-colors-green-400)",
      "--toastify-color-progress-success": "var(--chakra-colors-green-400)",
      "--toastify-icon-color-error": "var(--chakra-colors-red-400)",
      "--toastify-color-progress-error": "var(--chakra-colors-red-400)",
    },
    "#root": {
      position: "relative",
      minHeight: "100vh",
      height: "auto"
    },
    "img:-moz-loading": {
      visibility: "hidden",
    },
    ".Toastify__toast-body": {
      fontFamily: '"Poppins", sans-serif',
    },
    ".Toastify__toast": {
      borderRadius: 0,
    },
    ".slide-top": {
      animation: "slide-top .5s cubic-bezier(.25,.46,.45,.94) both",
    },
    "@keyframes slide-top": {
      "0%": {
        transform: "translateY(10px)",
      },
      "100%": {
        transform: "translateY(0px)",
      },
    },
    ".fade-out": {
      animation: "fade-out .1s cubic-bezier(.25,.46,.45,.94) both",
    },
    "@keyframes fade-out": {
      "0%": {
        opacity: "1",
      },
      "100%": {
        opacity: "0",
      },
    },
    "a:hover": {
      textDecoration: "underline",
    },
    ".time-range-slider": {
      width: "100%",
      height: "70px",
    },
    ".time-range-slider .react_time_range__rail__outer": {
      transform: "none",
      display: "none"
    },
    ".time-range-slider .react_time_range__rail__inner": {
      transform: "none",
      background: "custom.gray_50",
      border: "none",
    },
    ".time-range-slider .react_time_range__track__disabled": {
      transform: "none",
      background:
        "repeating-linear-gradient(-45deg, transparent, transparent 3px, var(--chakra-colors-red-400) 4px, var(--chakra-colors-red-400) 2px)",
      border: "none",
    },
    ".time-range-slider .react_time_range__track": {
      transform: "none",
      backgroundColor: "custom.brand_activeTimeRangeTrack !important",
      pointerEvents: "none",
    },
    ".time-range-slider-error .react_time_range__track": {
      backgroundColor: "var(--chakra-colors-red-400) !important",
    },
    ".time-range-slider .react_time_range__handle_wrapper": {
      top: "25px",
      display: "none"
    },
    ".time-range-slider div[role='slider']": {
      display: "none"
    },
    ".time-range-slider .react_time_range__handle_container": {
      top: "25px",
      borderRadius: "0",
    },
    ".time-range-slider .react_time_range__handle_container_disabled": {
      borderRadius: "0",
    },
    ".react_time_range__handle_marker": {
      background: "green.400",
    },
    ".react_time_range__handle_marker__error": {
      background: "red.400",
    },
    ".time-range-slider .react_time_range__tick_label": {
      marginTop: "56px",
      color: "custom.gray_700",
      fontFamily: '"Poppins", sans-serif',
      fontWeight: "medium",
    },
    ".time-range-slider .react_time_range__tick_marker__large": {
      marginTop: "44px",
      height: "6px",
    },
    ".time-range-slider div[disabled]" : {
      background: "red.300",
      border: 0,
      borderRadius: 0
    },
    ".chakra-table .chakra-checkbox__label" : {
      fontSize: "12px"
    }
  }),
};

export default styles;
