const input = {
  // Styles for the base style
  baseStyle: {
    title: {
      py: "0.2rem",
    },
    item: {
      py: "0.5rem",
      px: "1rem",
    },
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {},
};

export default input;
