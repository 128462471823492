import { createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "@utils/http";
import {
  getPrintJobRequestI,
  updatePrintJobRequestI,
} from "@features/print/types";
import { toast } from "react-toastify";
import i18n from "i18next";

export const getPrintJobs = createAsyncThunk(
  "byod/printJobs",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await http.get("/byod/printJobs/");
      return data;
    } catch (e) {
      rejectWithValue({ e });
    }
  }
);

export const getPrintJobsByIds = createAsyncThunk(
  "byod/printJob/{id}",
  async ({ id }: getPrintJobRequestI, { rejectWithValue }) => {
    try {
      const { data } = await http.get("/byod/printJob/" + id);
      return data;
    } catch (e) {
      rejectWithValue({ e });
    }
  }
);

export const updatePrintJob = createAsyncThunk(
  "byod/printJob",
  async ({ body }: updatePrintJobRequestI, { rejectWithValue }) => {
    try {
      const res = await http.put("/byod/printJob/", body);
      toast.success(i18n.t("printManagement.updatePrintJob.toast.successMsg"));
      return res;
    } catch (error) {
      toast.error(i18n.t("printManagement.updatePrintJob.toast.errorMsg"));
      return rejectWithValue(error);
    }
  }
);

export const deletePrintJobs = createAsyncThunk(
  "byod/printJobDelete",
  async ({ ids }: any, { rejectWithValue }) => {
    try {
      const res = await http.delete("/byod/printJob/" + ids);
      toast.success(i18n.t("printManagement.deletePrintJobs.toast.successMsg"));
      return res;
    } catch (error) {
      toast.error(i18n.t("printManagement.deletePrintJobs.toast.errorMsg"));
      return rejectWithValue(error);
    }
  }
);
