import { createAsyncThunk } from "@reduxjs/toolkit";
import { http } from "@utils/http";
import { LoginRequestI, LoginResponseDataI } from "@features/auth/types";
import { toast } from "react-toastify";
import i18n from "i18next";

export const handleLogin = createAsyncThunk(
  "front-office/login",

  async (
    { userLogonId, userFirstName, userName, userLanguage }: LoginRequestI,
    { rejectWithValue }
  ) => {
    try {
      const res = (await http.post("/front-office/login", {
        userLogonId,
        userFirstName,
        userName,
        userLanguage
      })) as LoginResponseDataI;

      localStorage.setItem("userToken", res.data.sessionToken);
      localStorage.setItem("userSettings", JSON.stringify({"BYODAvailable" : res.data.BYODAvailable, "reservePCAvailable": res.data.reservePCAvailable, "reservePlaceAvailable": res.data.reservePlaceAvailable}));

      return res.data;
    } catch (error) {
      toast.error(i18n.t("auth.login.toast.errorMsg"));
      return rejectWithValue(error);
    }
  }
);

export const handleLogout = createAsyncThunk(
  "front-office/logout",
  async (_, { rejectWithValue }) => {
    try {
      await http.delete("/front-office/logout");
      localStorage.removeItem("userToken");
      localStorage.removeItem("userSettings");

      toast.success(i18n.t("auth.logout.toast.successMsg"));

      return true;
    } catch (error) {
      toast.error(i18n.t("auth.logout.toast.errorMsg"));
      return rejectWithValue({ error });
    }
  }
);
