import {
  Flex,
  FormLabel,
  Image,
  Input,
  Link,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import uploadIcon from "../../assets/images/upload-icon.svg";
import { ChangeEvent, useEffect, useState } from "react";
import { handleUploadFile } from "@features/upload/action";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "@src/store";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { updatePrintJob } from "@features/print/action";
import { useAppSelector } from "@store/hooks";
import i18n from "i18next";

const Index = () => {
  const [files, setFiles] = useState<File[]>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { printJobsByIds } = useAppSelector((state: RootState) => state.print);

  const handleFileChange = (e: ChangeEvent<any>) => {
    if (e.target.files) {
      setFiles(e.target.files);
    }
  };

  const lang = i18n.language;

  useEffect(() => {
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();

        reader.onload = ((f: File) => {
          return (e: ProgressEvent<FileReader>) => {
            new Promise((resolve, reject) => {
              dispatch(
                handleUploadFile({
                  fileName: f.name,
                  body: e.target?.result,
                })
              )
                .then(({ error, payload }: any) => {
                  if (!error) {
                    const body = [payload].map(({ ID }: any) => {
                      return {
                        ID: ID,
                        PRINT_COLOR: 0,
                        PAGESIZE: 0,
                        PRINT_ORIENTATION: 0,
                        PRINT_DUPLEX: 0,
                        PRINT_COPIES: 0,
                        STATUS: 20,
                      };
                    });

                    dispatch(
                      updatePrintJob({
                        body: body,
                      })
                    )
                      .then(() =>
                        resolve(
                          navigate(`/${lang}${t("pageRoutes.bevestig-upload")}`)
                        )
                      )
                      .catch((err) => reject(err));
                  }
                })
                .catch((err) => reject(err));
            });
          };
        })(file);

        reader.readAsArrayBuffer(file);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, pathname, t, dispatch, files, printJobsByIds]);

  return (
    <>
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        width={"full"}
        padding={{ base: "20px", md: "32px", lg: "45px" }}
        border={"1px"}
        borderColor={"custom.gray_300"}
        position={"relative"}
      >
        <VStack spacing={"18px"}>
          <Image src={uploadIcon} alt={"upload icon"} maxW={"160px"} />
          <VStack spacing={"4px"}>
            <FormLabel textAlign={"center"} mb={"0"}>
              <Input
                type="file"
                onChange={handleFileChange}
                width={"100%"}
                height={"100%"}
                position={"absolute"}
                top={"0"}
                left={"0"}
                opacity={"0"}
                overflow={"hidden"}
                cursor={"pointer"}
                multiple
              />
              <Stack mb={"30px"}>
                <Link
                  color={"custom.brand_primary"}
                  fontWeight={"semibold"}
                  position={"relative"}
                  zIndex={9998}
                >
                  {t("uploadBox.title")}
                </Link>
                <Text>{t("uploadBox.description")}</Text>
              </Stack>
            </FormLabel>
            <Text fontSize={"14px"} textAlign={"center"}>
              {t("uploadBox.supportedFileExt")}
            </Text>
          </VStack>
        </VStack>
      </Flex>
    </>
  );
};

export default Index;
