const checkbox = {
    // Styles for the base style
    baseStyle: {
        label: {
            fontSize: '12px'
        },
    },
};

export default checkbox;
