const button = {
  // Styles for the base style
  baseStyle: {
    borderRadius: 0,
  },
  // Styles for the size variations
  sizes: {
    md: {
      height: "44px",
    },
  },
  // Styles for the visual style variations
  variants: {
    primary: {
      rounded: "none",
      background: "custom.brand_primary",
      color: "white",
      fontWeight: "normal",
      _focus: {
        ring: 2,
        ringColor: "custom.brand_primaryLight",
      },
      _hover: {
        _disabled: {
          background: "custom.brand_primary",
        },
      },
    },
    secondary: {
      rounded: "none",
      background: "black",
      color: "white",
      fontWeight: "normal",
      _focus: {
        ring: 0,
      },
      _hover: {
        _disabled: {
          background: "black",
        },
      },
    },
    primaryPageButtons: {
      width: "40px",
      height: "40px",
      background: "none",
      borderRadius: "50%",
      _active: {
        background: "custom.brand_primary",
        color: "white",
      },
    },
    outline: {
      fontWeight: "normal",
      borderColor: "custom.gray_300",
      borderWidth: "2px",
      _hover: {
        background: "custom.gray_50"
      }
    },
    datePicker: {
      fontWeight: "normal",
      borderWidth: "2px",
      _hover: {
        background: "custom.brand_primary",
        color: "white",
      },
    },
    filterButton: {
      width: "56px",
      borderWidth: "4px",
      borderColor: "custom.brand_primaryLight",
      borderRadius: "24px",
      background: "custom.brand_primary",
      color: "white",
    },
    link: {
      fontWeight: "normal",
      color: "custom.gray_700",
    },
    deleteLink: {
      padding: "0",
      fontWeight: "normal",
      color: "red.500",
      height: "auto",
      minWidth: "auto",
      _hover: {
        textDecoration: "underline",
      },
    },
  },
  // The default `size` or `variant` values
  defaultProps: {},
};

export default button;
