import React from "react";
import { Select } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { availableLanguages } from "@utils/i18n";
import {useDispatch} from "react-redux";
import {AppDispatch} from "@src/store";
import {setLang} from "@features/reservation/action";

interface LanguageDropdownOptionI {
  subPath?: string
}

const Index = ({subPath}: LanguageDropdownOptionI) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const changeLanguage = (locale: string) => {
    i18n.changeLanguage(locale).then(() => {
      dispatch(setLang({userLanguage: locale.toUpperCase()}))
      if (subPath) {
        navigate(`/${locale}${subPath}`);
      } else {
          navigate(`/${locale}`);
       }
    });
  };

  return (
    <Select
      defaultValue={i18n.language}
      onChange={(e) => changeLanguage(e.target.value)}
    >
      {availableLanguages.map((language) => (
        <option key={language}>{language}</option>
      ))}
    </Select>
  );
};

export default Index;
